import dayjs from "dayjs";
import { createContext, useState } from "react";

const FilterDataContext = createContext();

export const filterStatusOptions = {
    AllTransactions: 'Todas as transações',
    PAID: 'Paid',
    GENERATED: 'Generated',
    FAILED: 'Failed',
    REFUNDED: 'Refunded'
}


const FilterDataProvider = ({ children }) => {
    const [filterDate, setFilterDate] = useState({
    
        fromDate: dayjs().format('YYYY-MM-DD'),
        toDate: dayjs().format('YYYY-MM-DD')
    });

const [filterStatus, setFilterStatus] = useState(undefined);
const [isFiltered, setIsFiltered] = useState(false)



    return (
        <FilterDataContext.Provider value={{ filterDate, setFilterDate, filterStatus, setFilterStatus, isFiltered, setIsFiltered }}>
            {children}
        </FilterDataContext.Provider >
    );
};

export { FilterDataProvider, FilterDataContext };
