import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PixIcon from "@mui/icons-material/Pix";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../../public/izipaylogo.png";
import logomobile from "../../../public/zcolorido.png";
import { DarkModeContext } from "../../context/darkModeContext.jsx";
import { UserContext } from "../../context/loggedUserContext";
import SubTokenContext from "../../context/subTokenContext.jsx";
import SidebarItem from "../sidebarItem/SidebarItem";
import "./sidebar.scss";

const Sidebar = () => {
  const { darkMode, dispatch } = useContext(DarkModeContext);
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { token } = useContext(SubTokenContext);
  const [isSub, setIsSub] = useState(false);

  const handleSignOut = async () => {
    try {
      localStorage.clear();
      window.location.replace("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  useEffect(() => {
    const handleStorageChange = () => {
      if (token) {
        setIsSub(true);
      } else {
        setIsSub(false);
      }
    };
    handleStorageChange();
  }, [token]);

  return (
    <div className="w-2 sm:w-1/12 ismobile:w-2/12 min-w-[50px] sidebar min-h-screen border-r-2 border-zinc-300">
      <div className="h-16 sm:h-28 w-full border-b-2 border-zinc-300 flex items-center justify-center">
        <div className="w-11/12">
          <div className="hidden ismobile:block">
            <Link to="/" style={{ textDecoration: "none" }}>
              <img src={logo} alt="izipay Logo" className="logo" />
            </Link>
          </div>
          <div className="block ismobile:hidden">
            <Link to="/" style={{ textDecoration: "none" }}>
              <img src={logomobile} alt="izipay Logo" className="logo" />
            </Link>
          </div>
        </div>
      </div>
      <div className="p-13">
        <div className="mt-12 ml-1 items-center ismobile:items-baseline p-0 flex flex-col gap-9">
          {/* <p className="title">{t('MAIN')}</p> */}
          <SidebarItem
            icon={
              <DashboardIcon
                className={
                  darkMode ? "text-izired text-2xl " : "text-iziblue text-2xl "
                }
              />
            }
            route={{ path: "/", title: "Tela Inicial" }}
            itemDisabled={user?.access_level === 2 || user?.access_level === 3}
            classes={darkMode ? "text-izired" : "text-iziblue"}
          />

          <SidebarItem
            icon={
              <CreditCardIcon
                className={
                  darkMode ? "text-izired text-2xl " : "text-iziblue text-2xl "
                }
              />
            }
            route={{ path: "/transactions", title: "Transações" }}
            itemDisabled={user?.access_level === 3}
            classes={darkMode ? "text-izired" : "text-iziblue"}
          />

          <SidebarItem
            icon={
              <PixIcon
                className={
                  darkMode ? "text-izired text-2xl " : "text-iziblue text-2xl "
                }
              />
            }
            route={{ path: "/payments", title: "Funcionalidades" }}
            itemDisabled={isSub || user?.access_level === 2}
            classes={darkMode ? "text-izired" : "text-iziblue"}
          />

          <SidebarItem
            icon={
              <AccountCircleOutlinedIcon
                className={
                  darkMode ? "text-izired text-2xl " : "text-iziblue text-2xl "
                }
              />
            }
            route={{ path: "/profile", title: "Perfil" }}
            itemDisabled={
              isSub || user?.access_level === 2 || user?.access_level === 3
            }
            classes={darkMode ? "text-izired" : "text-iziblue"}
          />

          <SidebarItem
            icon={
              <SupervisorAccountIcon
                className={
                  darkMode ? "text-izired text-2xl " : "text-iziblue text-2xl "
                }
              />
            }
            route={{ path: "/userlist", title: "Gestão de Contas" }}
            itemDisabled={
              !user?.is_admin ||
              user?.access_level === 2 ||
              user?.access_level === 3
            }
            classes={darkMode ? "text-izired text-lg " : "text-iziblue text-lg"}
          />

          <div className="flex border-l-4 border-transparent">
            <div className=" self-center">
              <ExitToAppIcon
                className={
                  darkMode ? "text-izired text-2xl " : "text-iziblue text-2xl "
                }
                onClick={handleSignOut}
              />
            </div>
            <span
              onClick={handleSignOut}
              style={{ cursor: "pointer" }}
              className="hidden ismobile:block self-center mdscreen:text-lg font-semibold ml-3 text-zinc-600"
            >
              {t("Sair")}
            </span>
          </div>
        </div>
      </div>
      <div className="hidden sm:flex bottom justify-center mt-5 items-center gap-1">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;
