import React from "react";
import { Link } from "react-router-dom";

const SidebarItem = ({ icon, route, darkMode, itemDisabled, classes}) => {
  const getClass = (path) => {
    if (location.pathname === path) {
      return darkMode
        ? "border-l-4 !border-iziblue"
        : "border-l-4 !border-izired";
    } else {
      return "";
    }
  };

  return (
    <>
      {itemDisabled ? (
        <button disabled style={{ textDecoration: "none" }}>
          <div
            className={`${getClass(route.path)} flex 
               opacity-50 cursor-not-allowed 
            `}
          >
            <div className=" self-center">{icon}</div>
            <span className= {classes + " hidden ismobile:block self-center mdscreen:font-semibold ml-3 text-zinc-600"}>
              {route.title}
            </span>
          </div>
        </button>
      ) : (
        <Link to={route.path} style={{ textDecoration: "none" }}>
          <div
            className={`${getClass(route.path)} flex`}
          >
            <div className=" self-center">{icon}</div>
            <span className= {classes + " hidden ismobile:block self-center mdscreen:font-semibold ml-3 text-iziblue"}>
              {route.title}
            </span>
          </div>
        </Link>
      )}
    </>
  );
};

export default SidebarItem;
