import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Alert, InputAdornment } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import QRCode from "qrcode.react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { FaArrowDown, FaArrowUp, FaCheck } from "react-icons/fa";
import { FaRegCopy } from "react-icons/fa6";
import { GrTransaction } from "react-icons/gr";
import { NumericFormat } from "react-number-format";
import { useQuery } from "react-query";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import {
  generateCashIn,
  getBalance,
  requestWithdraw,
  tef,
  withdraw,
} from "../../services/TransactionsService";
import "./payments.scss";
import * as yup from "yup";
import { object } from "yup";
import { cpf, cnpj } from "cpf-cnpj-validator";
import {
  formatPhoneNumber,
  mask,
  maskOff,
  maskPhone,
} from "../../utils/validations";

const Payments = () => {
  const [qrCodeGenerated, setQrCodeGenerated] = useState("");
  const [pixKey, setPixKey] = useState("");
  const [virtualAccount, setVirtualAccount] = useState("");
  const [amount, setAmount] = useState("");
  const [cashoutAmount, setCashoutAmount] = useState("");
  const [tefAmount, setTefAmount] = useState("");
  const [cashoutAlert, setCashoutAlert] = useState(null); //Alert for Cash Out
  const [cashinAlert, setCashinAlert] = useState(null); // Alert for Cash In
  const [tefAlert, setTefAlert] = useState(null); // Alert for TEF
  const [showModal, setShowModal] = useState(false); // State for showing modal
  const [showQRCodeModal, setShowQRCodeModal] = useState(false);
  const [receiverData, setReceiverData] = useState(null); // State for receiver data
  const [pinCode, setPinCode] = useState(""); // State for pin code
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = useState(null);
  const [open, setOpen] = useState(false);
  const [cpfCashIn, setCpfCashIn] = useState("");
  const [nameCashIn, setNameCashIn] = useState("");
  const [emailCashIn, setEmailCashIn] = useState("");
  const [typePix, setTypePix] = useState("");
  const [maxLength, setMaxLength] = useState("");
  const [errorName, setErrorName] = useState(false);
  const [helperTextName, setHelperTextName] = useState("");
  const [errorCpfCnpj, setErrorCpfCnpj] = useState(false);
  const [helperTextCpfCnpj, sethelperTextCpfCnpj] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [helperTextEmail, setHelperTextEmail] = useState("");
  const [errorPixKey, setErrorPixKey] = useState(false);
  const [helperTextPixKey, setHelperTextPixKey] = useState("");

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
  }));

  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
    },
    focusedTextField: {
      "&.Mui-focused": {
        borderColor: "yellow", // Change this to the desired color
      },
    },
  });

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        setShowModal(false);
        setShowQRCodeModal(false);
      }
    };
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  const clearSuccessMessages = () => {
    setTimeout(() => {
      setCashoutAlert(null);
      setCashinAlert(null);
      setTefAlert(null);
    }, 5000);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(qrCodeGenerated);
    setShowQRCodeModal(false);
    setQrCodeGenerated("");
    setAmount("");
  };

  const handleTEFConfirm = async () => {
    setOpen(true);
    try {
      const amountValue = parseFloat(tefAmount);
      await tef(amountValue, virtualAccount);
      setTefAmount("");
      setVirtualAccount("");
      setTefAlert({ type: "success", text: t("TEF successful!") }); // <-- Set success message
      clearSuccessMessages();
    } catch (error) {
      setOpen(false);
      console.error("An error occurred:", error);
      setTefAlert({ type: "error", text: "An error occurred during TEF." }); // <-- Set error message
    } finally {
      setOpen(false);
    }
  };

  const validateEmailCashin = () => {
    const validationCashInSchema = object({
      nameCashIn: yup
        .string()
        .required()
        .min(3, "Mínimo de 3 caracteres para o campo Nome"),
      emailCashIn: yup
        .string()
        .required("O campo e-mail é obrigatório")
        .email("Um e-mail válido é necessário"),
    });
    const data = {
      nameCashIn: nameCashIn,
      emailCashIn: emailCashIn,
    };
    validationCashInSchema
      .validate(data)
      .then(
        (validData) => console.log("yup valid", validData),
        setErrorEmail(false),
        setHelperTextEmail("")
      )
      .catch((error) => {
        setErrorEmail(true);
        setHelperTextEmail(t(error.errors));
        console.error("An error occurred (yup):", error);
        setCashoutAlert({ type: "error", text: t(error.errors) });
      });
  };

  const handleCashinConfirm = async () => {
    setOpen(true);
    try {
      const amountValue = parseFloat(amount);

      if (!cpf.isValid(cpfCashIn) && !cnpj.isValid(cpfCashIn)) {
        throw new Error("O documento CPF/CNPJ não é valido");
      }

      const cpfCashInNoMask = maskOff(cpfCashIn);

      const response = await generateCashIn(
        amountValue,
        cpfCashInNoMask,
        nameCashIn,
        emailCashIn
      );
      setQrCodeGenerated(response?.qr_code_value);
      setShowQRCodeModal(true);
      setAmount("");
      setCashinAlert({ type: "success", text: t("Code Generated") }); // <-- Set success message
      clearSuccessMessages();
    } catch (error) {
      setOpen(false);
      console.error("An error occurred:", error);
      setCashinAlert({ type: "error", text: "Error generating QR Code." }); // <-- Set error message
    } finally {
      setOpen(false);
    }
  };

  const { data: metrics, refetch } = useQuery({
    queryKey: ["balance"],
    queryFn: () => getBalance(),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const validateName = () => {
    if (nameCashIn.length < 3) {
      setCashoutAlert({
        type: "error",
        text: "O nome deve ter pelo menos 3 caracteres",
      });
      setErrorName(true);
      setHelperTextName("O nome deve ter pelo menos 3 caracteres");
      setNameCashIn("");
      clearSuccessMessages();
    } else {
      setErrorName(false);
      setHelperTextName("");
    }
  };
  const validateCpfCnpj = () => {
    if (!cpf.isValid(cpfCashIn) && !cnpj.isValid(cpfCashIn)) {
      setCashoutAlert({ type: "error", text: "CPF/CNPJ inválidos!" });
      setErrorCpfCnpj(true);
      sethelperTextCpfCnpj("CPF/CNPJ inválidos!");
      clearSuccessMessages();
    } else {
      setErrorCpfCnpj(false);
      sethelperTextCpfCnpj("");
    }
  };

  const validatePixKey = () => {
    if (typePix === "cpf") {
      if (!cpf.isValid(pixKey)) {
        setCashoutAlert({ type: "error", text: "CPF inválido!" });
        setErrorPixKey(true);
        setHelperTextPixKey("CPF inválido!");
        clearSuccessMessages();
      } else {
        setErrorPixKey(false);
        setHelperTextPixKey("");
      }
    }
    if (typePix === "cnpj") {
      if (!cnpj.isValid(pixKey)) {
        setCashoutAlert({ type: "error", text: "CNPJ inválido!" });
        setErrorPixKey(true);
        setHelperTextPixKey("CNPJ inválido!");
        clearSuccessMessages();
      } else {
        setErrorPixKey(false);
        setHelperTextPixKey("");
      }
    }
    if (typePix === "email") {
      const validationCashOutSchema = object({
        pixKey: yup.string().required().email("É necessário um e-mail válido"),
      });
      const data = { pixKey: pixKey };

      validationCashOutSchema
        .validate(data)
        .then(
          (validData) => {
            console.log("yup valid", validData), setPixKey(validData.pixKey);
          },
          setErrorPixKey(false),
          setHelperTextPixKey("")
        )
        .catch((error) => {
          setErrorPixKey(true);
          setHelperTextPixKey(t(error.errors));
          console.error("An error occurred (yup):", error);
          setCashoutAlert({ type: "error", text: t(error.errors) });
        });
    }
  };

  const handleCashoutClick = async () => {
    setOpen(true);

    try {
      const amountValue = parseFloat(cashoutAmount);

      let pixKeyNoMask;
      if (typePix === "cpf" || typePix === "cnpj" || typePix === "phone") {
        pixKeyNoMask = maskOff(pixKey);
        if (typePix === "phone") {
          const phoneFormated = formatPhoneNumber(pixKeyNoMask);
          pixKeyNoMask = phoneFormated;
        }
      }
      if (typePix === "email" || typePix === "evp") {
        pixKeyNoMask = pixKey;
      }

      if (parseFloat(metrics?.accounts[0].balance) - amountValue < 0) {
        setCashoutAlert({ type: "error", text: t("Saldo Insuficiente.") });
        setTimeout(() => {
          setCashoutAlert(false);
        }, 2500);
        return;
      }

      const response = await requestWithdraw(
        amountValue,
        pixKeyNoMask,
        typePix
      );
      if (response) {
        setReceiverData({
          name: response.sender_name,
          accountNumber: response.sender_account_number,
          bankName: response.sender_account_bank_name,
          branch: response.sender_account_branch,
          documentNumber: response.sender_document_number,
          ispb: response.sender_account_bank_ispb,
        });
        setShowModal(true);

        setCashoutAlert({
          type: "success",
          text: t("Pin code send successfully!"),
        }); // <-- Set success message
        clearSuccessMessages();
      }
    } catch (error) {
      setOpen(false);
      console.error("An error occurred:", error);
      setCashoutAlert({
        type: "error",
        text: t("An error occurred during withdrawal."),
      }); // <-- Set error message
    } finally {
      setOpen(false);
    }
  };

  const handleCashoutConfirm = async () => {
    setOpen(true);
    refetch();
    try {
      let pixKeyNoMask;
      if (typePix === "cpf" || typePix === "cnpj" || typePix === "phone") {
        pixKeyNoMask = maskOff(pixKey);
        if (typePix === "phone") {
          const phoneFormated = formatPhoneNumber(pixKeyNoMask);
          pixKeyNoMask = phoneFormated;
        }
      }
      if (typePix === "email" || typePix === "evp") {
        pixKeyNoMask = pixKey;
      }
      const amountValue = parseFloat(cashoutAmount);
      const response = await withdraw(
        pinCode,
        amountValue,
        pixKeyNoMask,
        typePix
      );
      if (response) {
        setReceiverData({
          name: response.sender_name,
          accountNumber: response.sender_account_number,
          bankName: response.sender_account_bank_name,
          branch: response.sender_account_branch,
          documentNumber: response.sender_document_number,
          ispb: response.sender_account_bank_ispb,
        });
        setCashoutAmount("");
        setPixKey("");
        setTypePix("");
        setCashoutAlert({ type: "success", text: t("Withdrawal successful!") }); // <-- Set success message
        clearSuccessMessages();
      }
    } catch (error) {
      setOpen(false);
      console.error("An error occurred:", error);
      setCashoutAlert({
        type: "error",
        text: t("An error occurred during withdrawal."),
      }); // <-- Set error message
    } finally {
      setOpen(false);
    }
  };

  const handleModalConfirm = async () => {
    await handleCashoutConfirm();
    setShowModal(false);
    setPinCode("");
  };

  const divCashIn = useRef(null);
  const divCashOut = useRef(null);
  const divTef = useRef(null);

  const handleButtonClick = (buttonId, divRef) => {
    setActiveButton(buttonId);

    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowQRCodeModal(false);
  };

  const handleChangeTypePix = (event) => {
    setTypePix(event.target.value);
    setPixKey("");
    setErrorPixKey(false);
    setHelperTextPixKey("");
  };

  useEffect(() => {
    switch (typePix) {
      case "cpf":
        setMaxLength(14);
        break;
      case "cnpj":
        setMaxLength(18);
        break;
      case "email":
        setMaxLength(50);
        break;
      case "phone":
        setMaxLength(15);
        break;
      case "evp":
        setMaxLength(40);
        break;
      default:
        break;
    }
  }, [typePix]);

  return (
    <ThemeProvider theme={theme}>
      <div className="receipts">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Sidebar />
        <div className="w-full">
          <Navbar />
          {/* <Widget /> */}
          <div className="flex flex-col h-[calc(100%-112px)]">
            <div className="w-11/12 mx-auto gap-5 flex">
              <div
                ref={divCashIn}
                className="flex mt-5 mb-5 gap-5 w-1/2 self-start"
              >
                <Tooltip
                  title={<Typography fontSize={15}>Cash-In</Typography>}
                  placement="bottom"
                  arrow
                >
                  <div
                    onClick={() => handleButtonClick(1, divCashIn)}
                    className={
                      activeButton === 1
                        ? "active w-1/3 icon-containerdeposit cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired"
                        : "w-1/3 icon-containerdeposit cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired"
                    }
                  >
                    <div className="flex gap-2 text-xl justify-center">
                      <span className="hidden sm:block">{t("Depósito")}</span>
                      <div className="self-center">
                        <FaArrowDown className="icondeposit" />
                      </div>
                    </div>
                  </div>
                </Tooltip>
                <Tooltip
                  title={<Typography fontSize={15}>Cash-Out</Typography>}
                  placement="bottom"
                  arrow
                >
                  <div
                    onClick={() => handleButtonClick(2, divCashOut)}
                    className={
                      activeButton === 2
                        ? "active w-1/3 icon-containerwithdraw cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired"
                        : "w-1/3 icon-containerwithdraw cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired"
                    }
                  >
                    <div className="flex gap-2 text-xl justify-center">
                      <span className="hidden sm:block">{t("Saque")}</span>
                      <div className="self-center">
                        <FaArrowUp className="iconwithdraw" />
                      </div>
                    </div>
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    <Typography fontSize={15}>Transação Interna</Typography>
                  }
                  placement="bottom"
                  arrow
                >
                  <div
                    onClick={() => handleButtonClick(3, divTef)}
                    className={
                      activeButton === 3
                        ? "active w-1/3 icon-containertef icon-container cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired"
                        : "w-1/3 icon-containertef cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired"
                    }
                  >
                    <div className="flex gap-1 text-xl justify-center">
                      <span className="hidden sm:block">{t("TEF")}</span>
                      <div className="self-center">
                        <GrTransaction className="icontef" />
                      </div>
                    </div>
                  </div>
                </Tooltip>
              </div>
              <div className="flex mr-0 mt-5 mb-5 gap-5 w-1/2 self-center justify-end">
                <div className="w-48 cursor-pointer text-iziblue font-bold py-2 px-2 sm:px-4 rounded hover:text-izired">
                  <div className="flex gap-2 text-xsm justify-center">
                    <a
                      className="w-full h-full"
                      href="https://www.bcb.gov.br/en/currencyconversion"
                      target="_blank"
                    >
                      <span className="flex justify-end">
                        {t("*Conversor de Moeda")}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex h-full">
              <div className="h-full w-full flex">
                <div className="flex h-4/6 w-full flex-col justify-between">
                  <div
                    className={
                      activeButton === 1 || activeButton === null
                        ? "ordertransition w-11/12 flex justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue"
                        : "hidden sm:block ordertransition w-11/12 justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue"
                    }
                  >
                    <Grid container spacing={2}>
                      <Grid item md={5} xs={12}>
                        <div className="flex items-center h-full gap-2 w-full">
                          <LightTooltip
                            title="Adicione fundos à sua conta."
                            TransitionComponent={Zoom}
                            placement="left"
                          >
                            <InfoOutlinedIcon
                              style={{
                                fontSize: "large",
                                verticalAlign: "top",
                              }}
                            />
                          </LightTooltip>
                          <div>
                            <span className="text-2xl font-semibold">
                              {t("Realizar Depósito")}
                            </span>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={5} xs={12}>
                        <div className="flex gap-5 w-full content-end">
                          <div
                            className={
                              activeButton === 1
                                ? "noblurred blurreddiv flex w-full p-2 justify-end"
                                : "blurredfull blurreddiv flex w-full p-2 justify-end"
                            }
                          >
                            <div className="flex flex-col gap-5 items-center w-full p-2">
                              <TextField
                                error={errorName}
                                helperText={helperTextName}
                                required
                                label={t("Nome do responsável")}
                                variant="outlined"
                                value={nameCashIn}
                                disabled={activeButton === 1 ? false : true}
                                onBlur={validateName}
                                onChange={(e) => setNameCashIn(e.target.value)}
                                className="paymentComponent w-full"
                              />
                              <TextField
                                error={errorCpfCnpj}
                                helperText={helperTextCpfCnpj}
                                required
                                label={t("CPF/CNPJ do responsável")}
                                variant="outlined"
                                value={cpfCashIn}
                                inputProps={{
                                  maxLength: 18,
                                }}
                                disabled={activeButton === 1 ? false : true}
                                onBlur={validateCpfCnpj}
                                onChange={(e) =>
                                  setCpfCashIn(mask(e.target.value.trim()))
                                }
                                className="paymentComponent w-full"
                              />
                              <TextField
                                error={errorEmail}
                                helperText={helperTextEmail}
                                required
                                label={t("E-mail do responsável")}
                                variant="outlined"
                                type="email"
                                onBlur={validateEmailCashin}
                                value={emailCashIn}
                                disabled={activeButton === 1 ? false : true}
                                onChange={(e) =>
                                  setEmailCashIn(e.target.value.trim())
                                }
                                className="paymentComponent w-full"
                              />
                              <NumericFormat
                                required
                                customInput={TextField}
                                label={t("Amount (R$)")}
                                variant="outlined"
                                value={amount}
                                disabled={activeButton === 1 ? false : true}
                                onValueChange={(values) => {
                                  const { floatValue } = values;
                                  setAmount(
                                    floatValue ? floatValue.toFixed(2) : ""
                                  );
                                }}
                                thousandSeparator="."
                                decimalSeparator=","
                                fixedDecimalScale={true}
                                decimalScale={2}
                                prefix={"R$ "}
                                className="paymentComponent w-full"
                              />
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <div className="w-full h-full flex justify-center p-2">
                          <div className="h-full flex items-end">
                            <Button
                              variant="contained"
                              color="success"
                              disabled={
                                activeButton === 1 &&
                                nameCashIn &&
                                cpfCashIn &&
                                emailCashIn &&
                                amount &&
                                !errorName &&
                                !errorCpfCnpj &&
                                !errorEmail
                                  ? false
                                  : true
                              }
                              onClick={handleCashinConfirm}
                              className="paymentComponent paymentButton h-14 bg-green-700 icon-containercheck"
                              style={{
                                fontSize: "25px",
                              }}
                            >
                              <FaCheck className="iconcheck" />
                            </Button>
                          </div>
                          <div className="flex flex-col self-end">
                            <div
                              className={
                                cashinAlert
                                  ? "alertanimate absolute alertbox right-0 p-2 z-50"
                                  : " absolute alertbox right-0 p-2 z-50"
                              }
                            >
                              {cashinAlert && ( // <-- New block to display status message
                                <div>
                                  <Alert severity={cashinAlert.type}>
                                    {cashinAlert.text}
                                  </Alert>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>

                  <div
                    ref={divCashOut}
                    className={
                      activeButton === 2 || activeButton === null
                        ? "ordertransition w-11/12 flex justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue"
                        : "hidden sm:block ordertransition w-11/12 justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue"
                    }
                  >
                    <Grid container spacing={2}>
                      <Grid item md={5} xs={12}>
                        <div className="flex items-center h-full gap-2 w-full">
                          <LightTooltip
                            title="Retire fundos da sua conta."
                            TransitionComponent={Zoom}
                            placement="left"
                          >
                            <InfoOutlinedIcon
                              style={{
                                fontSize: "large",
                                verticalAlign: "top",
                              }}
                            />
                          </LightTooltip>
                          <div>
                            <span className=" text-2xl font-semibold">
                              {t("Realizar Saque")}
                            </span>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={5} xs={12}>
                        <div className="flex gap-5 w-full content-end">
                          <div
                            className={
                              activeButton === 2
                                ? "noblurred blurreddiv flex flex-col p-2 gap-3 w-full"
                                : "blurredfull blurreddiv flex flex-col p-2 gap-3 w-full"
                            }
                          >
                            <div className="flex flex-col gap-5 items-center w-full p-2">
                              <FormControl fullWidth>
                                <InputLabel
                                  className="hidden"
                                  id="demo-simple-select-label"
                                >
                                  Tipo Chave Pix
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={typePix}
                                  disabled={activeButton !== 2}
                                  label={t("Chave Pix")}
                                  onChange={handleChangeTypePix}
                                >
                                  <MenuItem value={"cpf"}>CPF</MenuItem>
                                  <MenuItem value={"cnpj"}>CNPJ</MenuItem>
                                  <MenuItem value={"email"}>E-mail</MenuItem>
                                  <MenuItem value={"phone"}>Celular</MenuItem>
                                  <MenuItem value={"evp"}>
                                    Chave Aleatória
                                  </MenuItem>
                                </Select>
                              </FormControl>
                              <TextField
                                error={errorPixKey}
                                helperText={helperTextPixKey}
                                label={t("Pix Key")}
                                variant="outlined"
                                value={pixKey}
                                inputProps={{
                                  maxLength: maxLength,
                                }}
                                disabled={activeButton === 2 ? false : true}
                                onChange={(e) => {
                                  if (typePix === "cpf" || typePix === "cnpj") {
                                    setPixKey(mask(e.target.value));
                                  } else if (typePix === "phone") {
                                    setPixKey(maskPhone(e.target.value));
                                  } else setPixKey(e.target.value.trim());
                                }}
                                onBlur={validatePixKey}
                                className="paymentComponent w-full"
                                placeholder={
                                  typePix === "phone"
                                    ? "DDD + número (sem espaços)"
                                    : ""
                                }
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {typePix === "phone" ? "+55" : ""}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <NumericFormat
                                customInput={TextField}
                                label={t("Amount (R$)")}
                                variant="outlined"
                                value={cashoutAmount}
                                disabled={activeButton === 2 ? false : true}
                                onValueChange={(values) => {
                                  const { floatValue } = values;
                                  setCashoutAmount(
                                    floatValue ? floatValue.toFixed(2) : ""
                                  );
                                }}
                                thousandSeparator="."
                                decimalSeparator=","
                                fixedDecimalScale={true}
                                decimalScale={2}
                                prefix={"R$ "}
                                className="paymentComponent w-full"
                              />
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <div className="w-full h-full flex justify-center p-2 pb-3">
                          <div className="h-full flex items-end">
                            <Button
                              variant="contained"
                              color="success"
                              onClick={handleCashoutClick}
                              disabled={
                                activeButton === 2 &&
                                typePix &&
                                pixKey &&
                                cashoutAmount &&
                                !errorPixKey
                                  ? false
                                  : true
                              }
                              className="paymentComponent paymentButton h-14 bg-green-700 icon-containercheck"
                              style={{
                                fontSize: "25px",
                              }}
                            >
                              <FaCheck className="iconcheck" />
                            </Button>
                          </div>
                          <div className="flex flex-col self-end">
                            <div
                              className={
                                cashoutAlert
                                  ? "alertanimate absolute alertbox right-0 p-2 z-50"
                                  : " absolute alertbox right-0 p-2 z-50"
                              }
                            >
                              {cashoutAlert && (
                                <div>
                                  <Alert severity={cashoutAlert.type}>
                                    {cashoutAlert.text}
                                  </Alert>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>

                  <div
                    ref={divTef}
                    className={
                      activeButton === 3 || activeButton === null
                        ? "ordertransition w-11/12 flex justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue"
                        : "hidden sm:block ordertransition w-11/12 justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue"
                    }
                  >
                    <Grid container spacing={2}>
                      <Grid item md={5} xs={12}>
                        <div className="flex items-center h-full gap-2 w-full">
                          <LightTooltip
                            title="Transfira fundos entre contas internas."
                            TransitionComponent={Zoom}
                            placement="left"
                          >
                            <InfoOutlinedIcon
                              style={{
                                fontSize: "large",
                                verticalAlign: "top",
                              }}
                            />
                          </LightTooltip>
                          <div>
                            <span className=" text-2xl font-semibold">TEF</span>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={5} xs={12}>
                        <div className="flex gap-5 w-full content-end">
                          <div
                            className={
                              activeButton === 3
                                ? "noblurred blurreddiv flex p-2 gap-3 w-full"
                                : "blurredfull blurreddiv flex p-2 gap-3 w-full"
                            }
                          >
                            <div className="flex flex-col gap-5 items-center w-full p-2">
                              <TextField
                                label={t("Virtual account")}
                                variant="outlined"
                                value={virtualAccount}
                                disabled={activeButton !== 3}
                                onChange={(e) =>
                                  setVirtualAccount(e.target.value.trim())
                                }
                                className="paymentComponent w-full"
                              />
                              <NumericFormat
                                customInput={TextField}
                                label={t("Amount (R$)")}
                                variant="outlined"
                                value={tefAmount}
                                disabled={activeButton !== 3}
                                onValueChange={(values) => {
                                  const { floatValue } = values;
                                  setTefAmount(
                                    floatValue ? floatValue.toFixed(2) : ""
                                  );
                                }}
                                thousandSeparator="."
                                decimalSeparator=","
                                fixedDecimalScale={true}
                                decimalScale={2}
                                prefix={"R$ "}
                                className="paymentComponent w-full"
                              />
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <div className="w-full h-full flex justify-center p-2">
                          <div className="h-full flex items-end">
                            <Button
                              variant="contained"
                              color="success"
                              disabled={
                                activeButton === 3 &&
                                virtualAccount &&
                                tefAmount
                                  ? false
                                  : true
                              }
                              onClick={handleTEFConfirm}
                              className="paymentComponent paymentButton h-14 bg-green-700 icon-containercheck"
                              style={{
                                fontSize: "25px",
                              }}
                            >
                              <FaCheck className="iconcheck" />
                            </Button>
                          </div>
                          <div className="flex flex-col self-end">
                            <div
                              className={
                                tefAlert
                                  ? "alertanimate absolute alertbox right-0 p-2 z-50"
                                  : " absolute alertbox right-0 p-2 z-50"
                              }
                            >
                              {tefAlert && ( // <-- New block to display status message
                                <div>
                                  <Alert severity={tefAlert.type}>
                                    {tefAlert.text}
                                  </Alert>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <div className="modalContainer">
            <div className="modalContent">
              <div className="flex text-center justify-end">
                <div>
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={handleCloseModal}
                    aria-label="delete"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              <div>
                <Typography variant="h5" component="h2" className="title">
                  {t("Beneficiary")}
                  <LightTooltip
                    title="Verifique o PIN no seu email cadastrado."
                    TransitionComponent={Zoom}
                    placement="bottom"
                    arrow
                  >
                    <InfoOutlinedIcon
                      style={{ fontSize: "large", verticalAlign: "top" }}
                    />
                  </LightTooltip>
                </Typography>
              </div>

              <Typography variant="body1" component="p" className="modalText">
                Nome: {receiverData.name}
              </Typography>
              <Typography variant="body1" component="p" className="modalText">
                Número da Conta: {receiverData.accountNumber}
              </Typography>
              <Typography variant="body1" component="p" className="modalText">
                Código do Banco: {receiverData.bankName}
              </Typography>
              <Typography variant="body1" component="p" className="modalText">
                Agência: {receiverData.branch}
              </Typography>
              <Typography variant="body1" component="p" className="modalText">
                Número do Documento: {receiverData.documentNumber}
              </Typography>
              <TextField
                inputProps={{
                  style: {
                    padding: "16px 12px",
                    borderColor: "#283650",
                  },
                }}
                sx={{ borderColor: "green" }}
                label="Pin"
                placeholder="Digite o PIN enviado via e-mail"
                variant="outlined"
                value={pinCode}
                onChange={(e) => setPinCode(e.target.value.trim())}
                className="w-full"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{ borderColor: "green" }}
                      position="end"
                    >
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#283650",
                        }}
                        onClick={handleModalConfirm}
                        className="modalButton"
                      >
                        {t("Confirm")}
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        )}
        {showQRCodeModal && (
          <div className="modalContainer">
            <div className="modalContent">
              <div className="flex text-center justify-end">
                <div>
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={handleCloseModal}
                    aria-label="delete"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              <div className="flex m-2 justify-center">
                <QRCode value={qrCodeGenerated} />
              </div>
              <div className="text-center">
                <span className="title">{t("Deposit into my account")}</span>
              </div>
              <div className=" my-2 break-words">{qrCodeGenerated}</div>
              <div className="flex justify-end">
                <button
                  onClick={handleCopyToClipboard}
                  className="flex gap-2 items-center rounded-full px-3 py-1 border-iziblue text-zinc-400 hover:shadow-lg"
                >
                  <FaRegCopy />
                  <span>{t("Copy PIX code")}</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default Payments;
