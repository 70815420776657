/* import "./list.scss"; */
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useContext, useEffect, useState } from "react";
import DatatableFee from "../../components/datatable/DatatableFees.jsx";
import Navbar from "../../components/navbar/Navbar";
import "../../components/navbar/navbar.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { DarkModeContext } from "../../context/darkModeContext.jsx"; // Import the DarkModeContext
// import { downloadCSV } from "../../services/TransactionsService";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getUsers } from "../../services/TransactionsService";
// import IconButton from '@material-ui/core/IconButton';
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { QueryClient, QueryClientProvider } from "react-query";
import { useMediaQuery } from "react-responsive";

const queryClient = new QueryClient();
const SearchBar = ({ onfilterDataFee }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  if (isMobile) {
    import("./listmobile.scss");
  } else {
    import("./list.scss");
  }

  const { t } = useTranslation();
  const [filterText, setFilterText] = React.useState("");
  const [status, setStatus] = React.useState(t("Sem Filtro"));
  const [movementType, setMovementType] = React.useState(t("Sem Filtro"));
  const [date, setDate] = React.useState(null);
  const [downloading, setDownloading] = useState(true);
  const [searchType, setSearchType] = useState("");
  const [inputDisabled, setInputdisabled] = useState(true);
  const [chips, setChips] = useState([]);
  const [appendValue, setAppend] = useState("");
  const [resetKeyAutocomplete, setResetKeyAutocomplete] = useState(uuidv4());
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSub, setAnchorElSub] = React.useState(null);
  const [openAutocompleteList, setOpenAutocompleteList] = useState(false);
  const [users, setUsers] = useState([]);
  const [defaultAutcompleteValue, setDefaultAutocompleteValue] = useState({
    description: "Sem Filtro",
    external_token: 0,
  });
  const handleSubSelect = (user) => {
    setDefaultAutocompleteValue(user);
    console.log(user);
    user.external_token == "0" ? clearSearch() : "";
    const chipval = `User: ${user.description}`;
    setChips([...chips, chipval]);
    setAnchorElSub(null);
    setOpenAutocompleteList(false);
  };
  const handleKeyPress = (event) => {
    // Check if the pressed key is Enter (key code 13)
    if (event.key === "Enter") {
      const chipval = searchType.replaceAll("_", " ") + ": " + filterText;
      setChips([...chips, chipval]);
      setDownloading(false);
      // Call your function here
      handleSearch();
    }
  };
  const handleSearchTypeChange = (event) => {
    event.target.value == ""
      ? (setInputdisabled(true), clearSearch())
      : setInputdisabled(false);
    setSearchType(event.target.value);
    setFilterText("");
    setDownloading(true);
  };
  const handleDeleteChip = (index, chip) => {
    const updatedChips = [...chips];
    updatedChips.splice(index, 1);
    setChips(updatedChips);
    chip === "PAID" ||
    chip === "GENERATED" ||
    chip === "FAILED" ||
    chip === "REFUNDED"
      ? setStatus(t("Sem Filtro"))
      : "";
    chip === "CASH-IN" || chip === "CASH-OUT" || chip === "TEF"
      ? setMovementType(t("Sem Filtro"))
      : "";
    const conditions = ["payment id"];
    conditions.some((el) => chip.includes(el))
      ? (setFilterText(""), setSearchType(""))
      : "";
    const conditionUser = ["User:"];
    conditionUser.some((el) => chip.includes(el))
      ? setDefaultAutocompleteValue({
          description: "Sem Filtro",
          external_token: 0,
        })
      : console.log("NAO SETOU BURRRRROOOOOOO");
    dayjs(chip, "DD/MM/YYYY", true).isValid() ? setDate(null) : "";
  };
  useEffect(() => {
    filterText == "" ? clearSearch() : "";
  }, [filterText]);
  useEffect(() => {
    status != t("Sem Filtro") || movementType != t("Sem Filtro")
      ? (handleSearch(), setDownloading(false))
      : clearSearch("status mov type");
  }, [status, movementType]);

  useEffect(() => {
    console.log(
      "defaultAutcompleteValue.external_token",
      defaultAutcompleteValue.external_token
    );
    console.log(
      "defaultAutcompleteValue.external_token",
      defaultAutcompleteValue.external_token !== 0
    );
    defaultAutcompleteValue.external_token !== 0
      ? handleSearch()
      : clearSearch("defaultAutcompleteValue");
  }, [defaultAutcompleteValue]);
  /* useEffect(() => {
      movementType != t('Sem Filtro') ? (handleSearch(), setDownloading(false)) : clearSearch();
    }, [movementType]); */

  const handleMovementTypeChange = (event) => {
    setMovementType(event.target.value);
    const updatedChips = [...chips];
    updatedChips.map((chip, index) => {
      if (chip === "CASH-IN" || chip === "CASH-OUT" || chip === "TEF") {
        chips.splice(index, 1);
        setChips(updatedChips);
      }
    });
    setChips([...chips, event.target.value.trim()]);
    setAppend("");
  };

  useEffect(() => {
    movementType != t("Sem Filtro")
      ? (handleSearch(), setDownloading(false))
      : clearSearch("mov type");
  }, [movementType]);
  useEffect(() => {
    const stringDate = dayjs(date).format("DD/MM/YYYY");
    const updatedChips = [...chips];
    updatedChips.map((chip, index) => {
      if (dayjs(chip, "DD/MM/YYYY", true).isValid()) {
        chips.splice(index, 1);
        setChips(updatedChips);
      }
    });
    date != null
      ? (handleSearch(),
        setDownloading(false),
        setChips([...chips, stringDate]),
        setAppend(""))
      : clearSearch("date effect");
  }, [date]);

  const clearSearch = (param) => {
    setSearchType("");
    setFilterText(filterText);
    setStatus(status);
    setMovementType(movementType);
    setDate(date);
    setDefaultAutocompleteValue(defaultAutcompleteValue);
    if (
      status == t("Sem Filtro") &&
      movementType == t("Sem Filtro") &&
      date == null &&
      filterText == ""
    ) {
      setDownloading(true);
    }
    onfilterDataFee();
    handleSearch();
  };
  const handleSearch = async () => {
    try {
      const filters = {};
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (searchType && filterText) filters[searchType] = filterText;
      if (filterText) return onfilterDataFee(filters);
      if (movementType != t("Sem Filtro"))
        filters["movement_type"] = movementType;
      if (date) filters["date"] = `${dayjs(date).format("YYYY-MM-DD")}`;
      if (status != t("Sem Filtro")) filters["status"] = status;
      if (defaultAutcompleteValue.external_token != "0")
        filters["user_id"] = defaultAutcompleteValue.user_id;
      onfilterDataFee(filters);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const fetchStatement = async () => {
      try {
        const response = await getUsers(1, 250, {});
        response.users.push({ description: "Sem Filtro", external_token: 0 });
        setUsers(response.users);
      } catch (error) {
        return;
      }
    };
    fetchStatement();
  }, []);
  return (
    <div className="w-full flex flex-col">
      <div className=" px-4 flex gap-3 w-full">
        <div className="w-4/12">
          <div className="w-full">
            <TextField
              sx={{
                width: "100%",
              }}
              className="textfield"
              id="search_value_input"
              label={<SearchIcon />}
              variant="outlined"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              onKeyDown={handleKeyPress}
              disabled={inputDisabled}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ marginRight: "-14px" }}
                  >
                    <Select
                      size="small"
                      sx={{
                        "&:hover": {
                          "&& fieldset": {
                            border: "1px solid #283650",
                          },
                        },
                        "&:active": {
                          "&& fieldset": {
                            border: "1px solid transparent",
                          },
                        },
                      }}
                      value={searchType}
                      onChange={handleSearchTypeChange}
                      displayEmpty
                      style={{ padding: 0, minWidth: "auto" }} // Reduce padding and minWidth
                    >
                      <MenuItem value="">{t("Sem Filtro")}</MenuItem>
                      <MenuItem
                        className="border-2 border-transparent hover:border-iziblue"
                        value={"payment_id"}
                      >
                        Payment ID
                      </MenuItem>
                    </Select>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className="w-4/12">
          <div>
            <Autocomplete
              disablePortal
              blurOnSelect
              disableClearable
              clearOnEscape
              key={resetKeyAutocomplete}
              getOptionLabel={(option) => option.description || option}
              open={openAutocompleteList}
              onOpen={() => setOpenAutocompleteList(true)}
              onChange={(event, value) => {
                handleSubSelect(value);
              }}
              value={defaultAutcompleteValue}
              onClose={() => setOpenAutocompleteList(false)}
              options={users}
              size="small"
              className="w-full"
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.external_token}>
                  {option.description}
                </Box>
              )}
              renderInput={(params) => <TextField {...params} label="Contas" />}
            />
          </div>
        </div>
        <div className="w-2/12 flex">
          <div className="dropdown w-full">
            <Box sx={{}}>
              <FormControl fullWidth>
                <InputLabel
                  id="movement_type_label"
                  style={{ fontWeight: "600" }}
                >
                  {t("Descrição")}
                </InputLabel>
                <Select
                  size="small"
                  labelId="movement_type"
                  id="movement_type"
                  value={movementType ?? t("Sem Filtro")}
                  label="movement_type"
                  disabled={!!filterText}
                  onChange={handleMovementTypeChange}
                >
                  <MenuItem value={t("Sem Filtro")} selected>
                    {t("Sem Filtro")}
                  </MenuItem>
                  <MenuItem value={"CASH-IN"}>Cash-in</MenuItem>
                  <MenuItem value={"CASH-OUT"}>Cash-out</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
        <div className="datepickers w-2/12">
          <div className="flex w-full gap-1">
            <div className="w-full">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  slotProps={{
                    textField: {
                      error: false,
                      size: "small",
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography style={{ fontWeight: "500" }}>
                              De:
                            </Typography>
                          </InputAdornment>
                        ),
                      },
                    },
                  }}
                  sx={{
                    width: "100%",
                  }}
                  disabled={!!filterText}
                  value={date}
                  onChange={(newValue) => setDate(newValue)}
                />
              </LocalizationProvider>
            </div>
            {/* <div className=' self-center'>
                  <div>to</div>
                </div> */}
            {/* <div className="w-full">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  slotProps={{
                    textField: {
                      error: false,
                      size: "small",
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography style={{ fontWeight: "500" }}>
                              Até:
                            </Typography>
                          </InputAdornment>
                        ),
                      },
                    },
                  }}
                  sx={{
                    width: "100%",
                  }}
                  disabled={!!filterText}
                  value={date}
                  readOnly
                />
              </LocalizationProvider>
            </div> */}
          </div>
        </div>
      </div>
      <div className="px-4 flex gap-3 w-full mt-4">
        {chips.map((chip, index) => (
          <Chip
            key={index}
            label={chip}
            className="border-zinc-400"
            onDelete={() => handleDeleteChip(index, chip)}
            variant="outlined"
            sx={{
              border: "2px solid rgb(161 161 170)",
              "& .MuiChip-label": {
                fontSize: 15,
                fontWeight: "500",
              },
            }}
          />
        ))}
      </div>
    </div>
  );
};
const List = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  if (isMobile) {
    import("./listmobile.scss");
  } else {
    import("./list.scss");
  }

  const { darkMode } = useContext(DarkModeContext); // Get the darkMode value from the context
  const [filterDataFee, setfilterDataFee] = useState({});

  const handlefilterDataFee = (data) => {
    setfilterDataFee(data);
  };
  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <div className={`list ${darkMode ? "dark" : ""} w-full`}>
        <Sidebar />
        <div className="w-5/12 sm:w-full homeContainer">
          <QueryClientProvider client={queryClient}>
            <Navbar />
          </QueryClientProvider>
          <div className="w-full">
            <div className="flex items-center justify-between mt-6 mb-2">
              <SearchBar onfilterDataFee={handlefilterDataFee} />
            </div>
            <DatatableFee filterDataFee={filterDataFee} />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default List;
