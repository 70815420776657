// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translation files
import translationEN from './locales/en.json'; // English translations
import translationsPTBR from './locales/pt_BR.json'; // Brazil translations
import translationsZHCN from './locales/zh_CN.json'; // Brazil translations

const resources = {
  en: {
    translation: translationEN,
  },
  'pt-BR': {
    translation: translationsPTBR,
  },
  'zh-CN': {
    translation: translationsZHCN,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'pt-BR', // Default language
    fallbackLng: 'pt-BR', // Fallback language
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
