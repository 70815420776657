import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Button, Modal } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  getStatementFees,
  getStatement,
} from "../../services/TransactionsService";
import { DarkModeContext } from "../../context/darkModeContext.jsx";
import { format } from "date-fns";
import { useTranslation } from "react-i18next"; // Import useTranslation
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import SubTokenContext from "../../context/subTokenContext";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
const formatCurrency = (value) => {
  const formattedValue = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);

  return formattedValue;
};

const formatDate = (value) => {
  const date = new Date(value);
  return format(date, "dd/MM/yyyy HH:mm");
};

const headCells = [
  {
    id: "last_updated_at",
    numeric: false,
    disablePadding: false,
    label: "Horário",
    class: "w-table-8",
    valueFormatter: ({ value }) => formatDate(value),
  },
  {
    id: "transaction_id",
    numeric: false,
    disablePadding: false,
    label: "Transaction ID",
    class: "w-table-20",
    width: 100,
  },
  {
    id: "payment_id",
    numeric: false,
    disablePadding: false,
    label: "Payment ID",
    class: "w-table-20",
    width: 100,
  },

  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Valor (R$)",
    class: "w-table-8",
    cellClassName: "cellWithStatus",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Tipo",
    class: "w-table-5",
    cellClassName: "cellWithStatus",
  },
  {
    id: "origin_account",
    numeric: false,
    disablePadding: false,
    label: "Origem",
    class: "w-table-15",
    cellClassName: "cellWithStatus text-ellipsis",
  },
  {
    id: "info",
    numeric: false,
    disablePadding: false,
    label: "Info",
    class: "w-table-2",
  },
  /* {
    id: 'movement_type',
    numeric: false,
    disablePadding: false,
    label: 'Cash flow',
    width: 100,
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount (R$)',
    width: 150,
    valueFormatter: ({ value }) => formatCurrency(value),
  },
  {
    id: 'fee_charged',
    numeric: true,
    disablePadding: false,
    label: 'Fee (R$)',
    width: 150,
    valueFormatter: ({ value }) => formatCurrency(value),
  },
  {
    id: 'account_owner_name',
    numeric: false,
    disablePadding: false,
    label: 'Account Owner Name',
    width: 100,
  },
  {
    id: 'end_to_end_id',
    numeric: false,
    disablePadding: false,
    label: 'End to end ID',
    width: 350,
  },
  {
    id: 'order_id',
    numeric: false,
    disablePadding: false,
    label: 'Order ID',
    width: 350,
  },
  {
    id: 'download_receipt',
    numeric: false,
    disablePadding: true,
    label: 'Download Receipt',
    width: 100,
  }, */
];

function EnhancedTableHead(props) {
  const { order, orderBy } = props;
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.class + " cursor-default"}
          >
            {headCell.id != "status" ? t(headCell.label) : headCell.label}
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function EnhancedTable({ filterDataFee }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [statementRows, setStatementRows] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("last_updated_at");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [totalElements, setTotalElements] = React.useState(1);
  const [loading, setLoading] = useState(true); // Introduce loading state
  const { darkMode } = useContext(DarkModeContext); // Get the darkMode value from the context
  const [modalData, setModalData] = useState(null);
  const { token } = useContext(SubTokenContext);
  useEffect(() => {
    const fetchStatement = async () => {
      try {
        setLoading(true);
        console.log(filterDataFee);
        const response = await getStatementFees(
          page + 1,
          rowsPerPage,
          filterDataFee
        );

        /* response.totals.transactions_qty == 0 ? response.totals.transactions_qty = 60 : ''; */
        setTotalElements(response.totals.transactions_qty);
        /* 
        {
            "trasaction_id": "F-25c7d72b-906b-42b1-8c6c-064557414e09",
            "payment_id": "SP-6c31899a-d8cc-4fb6-9d4c-ffc2018f4a07",
            "total_paid": "30.00",
            "payment_method": "CASH-IN STATIC",
            "paid_at": "2024-05-19T08:08:04.000Z",
            "from_user": "IZI - Ekelsio Equip 2",
            "to_user": "[Fees] V2 ADM Account",
            "fee_type": "percentage",
            "fee_amount": "2.50",
            "fee_from": "CASH-IN"
        } */
        const transactions = response?.transactions?.map((t) => ({
          ...t,
          key: t.transaction_id,
          payment_id: t?.payment_id,
          total_paid: t?.total_paid,
          payment_method: t?.payment_method,
          paid_at: t?.paid_at,
          from_user: t?.from_user,
          to_user: t?.to_user,
          fee_type: t?.fee_type,
          fee_amount: t?.fee_amount,
          fee_from: t.fee_from,
        }));
        /* const transactions = response?.transactions?.map((t) => ({
          ...t,
          key: t.transaction_id,
          last_updated_at: t?.last_updated_at,
          end_to_end_id: t?.end_to_end_id,
          order_id: t?.order_id,
          status: t?.status,
          movement_type: t?.movement_type,
          amount: t?.amount,
          fee_charged: t?.fee_charged,
          account_owner_name: t?.account_owner_name,
          statusMessage: statusMessage(t?.status),
        })); */
        setStatementRows(transactions);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setStatementRows([]);
        console.error(error);
        return;
      }
    };
    fetchStatement();
  }, [filterDataFee, page, rowsPerPage, token]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 15));
    setPage(0);
  };

  if (loading) {
    return (
      <div className="text-center mt-24">
        <CircularProgress color="inherit" />
      </div>
    ); // Render a loading state while data is being fetched
  }
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const handleOpen = (row) => {
    setModalData(
      <div>
        <div id="modal-modal-title">
          <div className="flex flex-col place-content-between gap-2">
            <div className="flex">
              <div className="w-full text-medium">
                Transação: <span className="font-semibold">{row.key}</span>
              </div>
            </div>
            <div className="flex gap-4 text-medium">
              <div>
                Data:{" "}
                <span className="font-semibold">
                  {dayjs(row.paid_at).format("DD/MM/YYYY")}
                </span>
              </div>
              <div>
                Horário:{" "}
                <span className="font-semibold">
                  {dayjs(row.paid_at).format("HH:mm")}
                </span>
              </div>
            </div>
            <div className="flex self-center font-bold text-xl text-nowrap">
              <div>{row.from_user}</div>
            </div>
            <div className="flex self-center font-bold text-xl">
              <div>{row.to_user}</div>
            </div>
          </div>
        </div>
        <div id="modal-modal-description" className="mt-1">
          <div className="flex flex-col">
            <div className="flex place-content-between text-medium">
              <div>
                Tipo:{" "}
                <span className=" font-semibold">
                  {row.fee_type === "percentage" ? "Porcentagem" : "Valor"}
                </span>
              </div>
              <div>
                Operação: <span className=" font-semibold">{row.fee_from}</span>
              </div>
            </div>
            <div className="flex place-content-between text-medium">
              <div>
                {row.fee_type === "percentage" ? "Porcentagem:" : "Valor:"}
                <span className=" font-semibold">
                  {row.fee_type == "percentage"
                    ? ` ${row.fee_amount}%`
                    : ` ${formatCurrency(parseFloat(row.fee_amount / 100))}`}
                </span>
              </div>
              <div>
                Total:{" "}
                <span className=" font-semibold">
                  {formatCurrency(parseFloat(row.total_paid / 100))}
                </span>
              </div>
            </div>
            <div className="flex place-content-between text-medium">
              <div>
                Original ID:{" "}
                <span className="font-semibold">{row.original_id}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    setOpen(true);
  };
  const handleOpenTransaction = async (row) => {
    setOpen(true);
    setModalData(
      <div className="text-center mt-24 mb-24">
        <CircularProgress color="inherit" />
      </div>
    );
    const response = await getStatement(1, rowsPerPage, {
      transaction_id: row.payment_id,
    });
    setModalData(
      <div>
        <div id="modal-modal-title">
          <div className="flex flex-col place-content-between gap-2">
            <div className="flex">
              <div className="w-full text-xl">
                <span className="font-bold">
                  {response.transactions[0]?.user_description}
                </span>
              </div>
            </div>
            <div className="flex">
              <div className="w-full text-medium">
                Transação:{" "}
                <span className="font-semibold">
                  {response.transactions[0]?.transaction_id}
                </span>
              </div>
            </div>
            <div className="flex gap-4 text-medium">
              <div>
                Data:{" "}
                <span className="font-semibold">
                  {dayjs(response.transactions[0]?.last_updated_at).format(
                    "DD/MM/YYYY"
                  )}
                </span>
              </div>
              <div>
                Horário:{" "}
                <span className="font-semibold">
                  {dayjs(response.transactions[0]?.last_updated_at).format(
                    "HH:mm"
                  )}
                </span>
              </div>
            </div>
            <div className="flex self-center font-bold text-xl">
              <div>{response.transactions[0]?.account_owner_name}</div>
            </div>
            <div className="flex self-center font-bold text-xl">
              <div>{response.transactions[0]?.payload_account_owner_name}</div>
            </div>
            <div className="flex">
              <div className="text-medium">
                Documento:{" "}
                <span className=" font-semibold">
                  {response.transactions[0]?.document_number}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="modal-modal-description">
          <div className="flex flex-col">
            <div className="flex place-content-between text-medium">
              <div>
                Agência:{" "}
                <span className=" font-semibold">
                  {response.transactions[0]?.account_branch}
                </span>
              </div>
              <div>
                Conta:{" "}
                <span className=" font-semibold">
                  {response.transactions[0]?.account_number}
                </span>
              </div>
            </div>
            <div className="flex place-content-between text-medium">
              <div>
                Descrição:{" "}
                <span className=" font-semibold">
                  {response.transactions[0]?.movement_type}
                </span>
              </div>
              <div>
                Status:{" "}
                <span className=" font-semibold">
                  {response.transactions[0]?.status}
                </span>
              </div>
            </div>
            <div className="flex place-content-between text-medium">
              <div>
                End to End ID:{" "}
                <span className="font-semibold">
                  {response.transactions[0]?.end_to_end_id}
                </span>
              </div>
            </div>
            <div className="flex place-content-between text-medium">
              <div>
                Valor da taxa:
                <span className="font-semibold">
                  R$ {response.transactions[0]?.fee_charged}
                </span>
              </div>
              <div>
                Status da taxa:{" "}
                <span className="font-semibold">
                  {response.transactions[0]?.fees_status}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const handleClose = () => {
    setOpen(false);
    setModalData(null);
  };
  return (
    <div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {modalData}
            {/* <div id="modal-modal-title" variant="h6" component="h2">
              Transação: {row.transaction_id}
            </div>
            <div id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="flex flex-col">
                <div className="flex place-content-between">
                  <div>DATA: {dayjs(row.last_updated_at).format('DD/MM/YYYY')}</div>
                  <div>HORARIO: {dayjs(row.last_updated_at).format('HH:mm')}</div>
                </div>
                <div>E2E ID: XXXXXXX</div>
                <div>TRANSACTION ID: XXXXXXXX</div>
                <div>ORDER ID: XXXXXXXXX</div>
                <div className="flex place-content-between">
                  <div>DESCRICAO: xxxx</div>
                  <div>STATUS: xxxx</div>
                </div>
              </div>
            </div> */}
          </Box>
        </Modal>
      </div>
      <Paper sx={{ width: "100%", mb: 2 }} className="table">
        <TableContainer className="table">
          <Table
            sx={{ minWidth: 0 }}
            aria-labelledby="tableTitle"
            size="medium"
            className="table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              className="table"
            />
            <TableBody>
              {statementRows.map((row, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  selected={false}
                  sx={{ cursor: "pointer" }}
                  className="table" // Apply dark mode class to each row
                  style={
                    darkMode
                      ? index % 2
                        ? { background: "rgba(148, 148, 148, 0.3)" }
                        : { background: "rgba(148, 148, 148, 0.1)" }
                      : index % 2
                      ? { background: "rgba(148, 148, 148, 0.3)" }
                      : { background: "white" }
                  }
                >
                  <TableCell
                    sx={{ padding: "5px" }}
                    className="tableCell "
                    align="center"
                  >
                    {formatDate(row.paid_at)}
                  </TableCell>
                  <TableCell
                    sx={{ padding: "5px" }}
                    className="tableCell "
                    align="center"
                  >
                    {row.key}
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "5px",
                      fontWeight: "500",
                      color: "rgb(202 72 56 )",
                    }}
                    className="tableCell text-izired font-semibold hover:text-iziblue"
                    align="center"
                    onClick={() => handleOpenTransaction(row)}
                  >
                    <div className="flex justify-center">
                      <div className="flex items-center">
                        <InfoOutlinedIcon
                          style={{
                            fontSize: "medium",
                            verticalAlign: "center",
                          }}
                        />
                      </div>
                      <div>{row.payment_id}</div>
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "5px" }}
                    className="tableCell "
                    align="center"
                  >
                    {formatCurrency(parseFloat(row.total_paid / 100))}
                  </TableCell>
                  <TableCell
                    sx={{ padding: "5px" }}
                    className="tableCell "
                    align="center"
                  >
                    <span className={`movement_type ${row.fee_from}`}>
                      {row.fee_from}
                    </span>
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "5px",
                      fontWeight: "500",
                      color: "rgb(202 72 56 )",
                    }}
                    className="tableCell text-izired font-semibold hover:text-iziblue text-ellipsis text-nowrap overflow-hidden"
                    align="center"
                  >
                    <Link
                      to={`/userprofile/${row.from_user_id}/${row.from_external_token}`}
                    >
                      <div className="flex justify-center">
                        <div className="flex items-center">
                          <AccountCircleOutlinedIcon
                            style={{
                              fontSize: "medium",
                              verticalAlign: "center",
                            }}
                          />
                        </div>
                        <div>{row?.from_user}</div>
                      </div>
                    </Link>
                  </TableCell>

                  <TableCell
                    sx={{ padding: "0px" }}
                    className="tableCell "
                    align="center"
                  >
                    <Button onClick={() => handleOpen(row)}>
                      {" "}
                      <AddCircleOutlineIcon
                        className={
                          darkMode
                            ? "text-izired text-2xl "
                            : "text-iziblue text-2xl "
                        }
                      />
                    </Button>
                  </TableCell>
                  {/*
                  <TableCell className="tableCell">
                    <span className={`movement_type ${row.movement_type}`}>{row.movement_type}</span>
                  </TableCell>
                  <TableCell className="tableCell">{formatCurrency(row.fee_charged)}</TableCell>
                  <TableCell className="tableCell" align="left">{row.order_id}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15]}
          component="div"
          count={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="table"
          labelDisplayedRows={({ from, to, count }) =>
            t("Mostrando") +
            ` ${from} ` +
            t("a") +
            ` ${to} ` +
            t("de um total de") +
            ` ${count} ` +
            t("transações")
          }
        />
      </Paper>
    </div>
  );
}
