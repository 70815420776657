import { useContext } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./auth/PrivateRoute";
import PrivateRouteLogin from "./auth/PrivateRouteLogin";
import PrivateRoutePayments from "./auth/PrivateRoutePayments";
import PrivateRouteProfile from "./auth/PrivateRouteProfile";
import PrivateRouteTransactions from "./auth/PrivateRouteTransactions";
import Loginexb from "./components/login/Loginexb";
import { DarkModeContext } from "./context/darkModeContext";
import { FilterDataProvider } from "./context/filterDataContext";
import { UserContextProvider } from "./context/loggedUserContext";
import { SubTokenProvider } from "./context/subTokenContext";
import UserList from "./pages/admin/UserList";
import UserProfile from "./pages/admin/UserProfile";
import Home from "./pages/home/Home";
import FeesTransactions from "./pages/list/FeesList";
import Transactions from "./pages/list/List";
import Payments from "./pages/payments/Payments";
import Profile from "./pages/profile/Profile";
import Receipts from "./pages/receipts/Receipts";
import "./style/dark.scss";

const queryClient = new QueryClient();

function App() {
  const { darkMode } = useContext(DarkModeContext);
  return (
    <FilterDataProvider>
      <SubTokenProvider>
        <UserContextProvider>
          <div className={darkMode ? "app dark" : "app"}>
            <BrowserRouter>
              <Routes>
                <Route element={<PrivateRouteLogin />}>
                  {/* <Route exact path="/" element={<PrivateRouteHome />}> */}
                    <Route path="/" element={<Home />} />
                  {/* </Route> */}
                  <Route
                    exact
                    path="/transactions"
                    element={<PrivateRouteTransactions />}
                  >
                    <Route path="/transactions" element={<Transactions />} />
                  </Route>
                  <Route
                    exact
                    path="/transactions/fees"
                    element={<PrivateRoute />}
                  >
                    <Route
                      path="/transactions/fees"
                      element={<FeesTransactions />}
                    />
                  </Route>
                  <Route
                    exact
                    path="/payments"
                    element={<PrivateRoutePayments />}
                  >
                    <Route
                      path="/payments"
                      element={
                        <QueryClientProvider client={queryClient}>
                          <Payments />
                        </QueryClientProvider>
                      }
                    />
                  </Route>
                  <Route path="/receipts" element={<Receipts />} />
                  <Route
                    exact
                    path="/profile"
                    element={<PrivateRouteProfile />}
                  >
                    <Route path="/profile" element={<Profile />} />
                  </Route>
                  {/* <Route path="/admin" element={<Admin/>} /> */}
                  {/* Routes bellow are private, check PrivateRoute.jsx */}
                  <Route exact path="/userlist" element={<PrivateRoute />}>
                    <Route path="/userlist" element={<UserList />} />
                  </Route>
                  <Route exact path="/userprofile" element={<PrivateRoute />}>
                    <Route
                      path="/userprofile/:user_id/:external_token"
                      element={<UserProfile />}
                    />
                  </Route>
                </Route>
                <Route path="/login" element={<Loginexb />} />
                <Route path="*" element={<PrivateRouteLogin />} />
              </Routes>
            </BrowserRouter>
          </div>
        </UserContextProvider>
      </SubTokenProvider>
    </FilterDataProvider>
  );
}

export default App;
