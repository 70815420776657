import React, { useState, useRef, useEffect } from "react";
import {
  getAllDocuments,
  updateStatus,
} from "../../services/TransactionsService";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { QueryClient, QueryClientProvider } from "react-query";
import { useLocation } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@material-ui/core/TextField";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CreateIcon from "@mui/icons-material/Create";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BlockIcon from "@mui/icons-material/Block";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useMediaQuery } from "react-responsive";
import {
  Image,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
  Font,
  Link,
} from "@react-pdf/renderer";
import myFontRegular from "../../../public/fonts/montserrat/Montserrat-Medium.ttf";
import myFontBold from "../../../public/fonts/montserrat/Montserrat-Bold.ttf";
import myFontSemiBold from "../../../public/fonts/montserrat/Montserrat-SemiBold.ttf";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import dayjs from "dayjs";
import ReactPDF from "@react-pdf/renderer";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Zoom from "@mui/material/Zoom";
import { useParams } from "react-router-dom";

const queryClient = new QueryClient();
const labelFontSize = "1.05rem";
const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: labelFontSize,
          fontWeight: 700,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& > fieldset > legend": {
            fontSize: `calc(0.75 * ${labelFontSize})`,
          },
        },
      },
    },
  },
});

const styles = StyleSheet.create({
  image: {
    width: "100%",
    height: "100%",
    zIndex: 1,
    /*  position: "relative", */
  },
  view: {
    padding: "20px",
    zIndex: 5,
    /* height: "100%" */
  },
  vieww50: {
    width: "50%",
  },
  page: {
    fontFamily: "myFontRegular",
    /* height: "100%" */
  },
  pageText: {
    fontFamily: "myFontRegular",
    /* height: "100%" */
  },
  textTitle: {
    fontSize: "30px",
    fontWeight: 600,
    color: "#283650",
    position: "absolute",
    left: "80px",
    right: "0px",
    zIndex: 5,
  },
  textLabel: {
    color: "#ca4838",
  },
  text: {
    fontSize: "12px",
    color: "#283650",
    position: "absolute",
    left: "80px",
    right: "0px",
    zIndex: 5,
  },
  textpassword: {
    fontSize: "12px",
    color: "#283650",
    position: "absolute",
    left: "77px",
    right: "0px",
    zIndex: 5,
  },
});
Font.register({
  family: "myFontRegular",
  fonts: [
    { src: myFontRegular, fontWeight: "normal" },
    { src: myFontBold, fontWeight: "bold" },
    { src: myFontSemiBold, fontWeight: 600 },
  ],
});
export function syntaxHighlight(json) {
  if (!json) return ""; //no JSON from response

  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function (match) {
      var cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    }
  );
}
const PdfTemplate = ({ user }) => (
  <Document>
    <Page size="A4" orientation="landscape">
      <Image style={styles.image} src="../../../public/bgfirstslide.png" />
    </Page>
    <Page style={styles.pageText} size="A4" orientation="landscape">
      <View>
        <Image style={styles.image} src="../../../public/bgslide.png" />
      </View>
      <View>
        <Text style={{ bottom: "400px", ...styles.textTitle }}>
          AMBIENTE PRODUÇÃO
        </Text>
      </View>
      <View style={styles.vieww50}>
        <Text style={{ bottom: "350px", ...styles.text }}>
          - Abaixo você encontra sua conta no ambiente de produção para realizar
          transações homologadas ao Banco Central do Brasil.
        </Text>
      </View>
      <View>
        <Text style={{ bottom: "325px", ...styles.text }}>
          <Text style={styles.textLabel}>Url Login: </Text>{" "}
          <Link src="https://dash-v2.izipaynow.com/">
            https://dash-v2.izipaynow.com/{" "}
          </Link>
        </Text>
      </View>
      <View>
        <Text style={{ bottom: "275px", ...styles.textTitle }}>
          Dados da Conta
        </Text>
      </View>
      <View>
        <Text style={{ bottom: "250px", ...styles.text }}>
          <Text style={styles.textLabel}>Nome da Conta: </Text>{" "}
          {user.description}
        </Text>
      </View>

      <View>
        <Text style={{ bottom: "225px", ...styles.text }}>
          <Text style={styles.textLabel}>Usuário: </Text> {user.username}
        </Text>
      </View>
      <View>
        <Text style={{ bottom: "200px", ...styles.textpassword }}>
          <Text style={styles.textLabel}> Senha: </Text> {user.password}
        </Text>
      </View>
    </Page>
    <Page size="A4" orientation="landscape">
      <Image style={styles.image} src="../../../public/bgthirdslide.png" />
    </Page>
  </Document>
);
const UserProfile = () => {
  const { user_id } = useParams();
  const { external_token } = useParams();
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [loading, setLoading] = useState(false);
  const [filteredJson, setfilteredJson] = useState({});
  const [Webhook, setWebHook] = useState("");
  const removeKeys = (keysToRemove, object) => {
    // Create a copy of the data object
    const newData = { ...object };
    // Iterate over the keys to be removed
    keysToRemove.forEach((key) => {
      // Remove each key from the copied object
      delete newData[key];
    });
    // Update the state with the new object
    setfilteredJson(newData);
  };
  const [user_data, setUser] = useState("");
  if (isMobile) {
    import("./adminMobile.scss");
  } else {
    import("./userList.scss");
  }
  function getTypeAccount(user) {
    if (user.is_fees_account == "1") {
      return "Fees";
    } else if (user.is_admin == "1") {
      return "Admin";
    } else if (user.is_parent == "1") {
      return "Subconta";
    } else {
      return "Master";
    }
  }
  useEffect(() => {
    fetchStatement();
  }, []);
  const fetchStatement = async () => {
    try {
      const response = await getAllDocuments(user_id);
      setUser(response[0]);
      if (response[0].confirm_cashout_webhook_url == "") {
        response[0].confirm_cashout_webhook_url = null;
      }
      if (response[0].cnpj == "") {
        response[0].cnpj = null;
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  useEffect(() => {
    if (user_data !== null) {
      removeKeys(
        [
          "document_photo_content",
          "user_with_document_photo_content",
          "proof_business_address_photo",
          "social_contract_photo",
          "cnpj_register_photo",
        ],
        user_data
      );
    }
  }, [user_data]);
  const [searchVal, setSearchVal] = useState("");
  const [resultVal, setResultVal] = useState("");
  const [scriptState, setScriptState] = useState("");
  const [copiedState, setCopiedState] = useState(
    "Clique aqui para copiar o campo."
  );
  const [copiedStateScript, setCopiedStateScript] = useState(
    "Clique aqui para gerar o script e copiar o resultado."
  );
  const divRef = useRef(null);
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
  }));
  const countWordsInsideBrackets = (str) => {
    const regex = /{{(.*?)}}/g;
    const matches = str.match(regex);
    const modifiedArray = matches.map((str) =>
      str.replace(new RegExp("{", "g"), "")
    );
    const finalarray = modifiedArray.map((str) =>
      str.replace(new RegExp("}", "g"), "")
    );
    return matches ? finalarray : 0;
  };
  const scrollToMiddle = () => {
    // Check if the divRef has been initialized
    window.scrollTo({
      top: document.documentElement.scrollHeight, // Scroll to the bottom of the page
      behavior: "smooth", // Smooth scrolling behavior
    });
  };
  const handleclick = (users) => () => {
    const keyArray = countWordsInsideBrackets(searchVal);
    let parsedTest = searchVal;
    keyArray.forEach((key) => {
      if (users.hasOwnProperty(key)) {
        parsedTest = parsedTest.replace("{{" + key + "}}", users[key]);
      }
    });
    setResultVal(parsedTest);
    navigator.clipboard.writeText(parsedTest);
    setCopiedStateScript("Resultado gerado e resposta copiada!");
  };
  const handleScript = (data) => {
    data != "payload" ? setTimeout(scrollToMiddle, 205) : "";
    data == scriptState ? setScriptState("") : setScriptState(data);
  };
  const handleStatus = (data) => {
    setUser("");
    const fetchStatementUpdate = async () => {
      try {
        const response = await updateStatus(data.status, data.user_id);
      } catch (error) {
        console.error(error);
        return;
      } finally {
        await fetchStatement();
      }
    };
    fetchStatementUpdate();
  };
  const handleWebHook = (user, type) => {
    const webhook = `
        curl --location 'https://api.izipaynow.com/v2/sandbox/qr-code/generate'
        --header 'Authorization: ${user.external_token}
        --header 'accept: application/json'
        --header 'content-type: application/json'
        --data '{"amount":1000}'
        `;

    setWebHook(webhook);
  };
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedState("Campo Copiado!");
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
      });
  };
  const statusUser = {
    0: "PENDING",
    1: "APPROVED",
    2: "SUSPENDED",
    3: "BLOCKED",
  };
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <div className="profile">
          <Sidebar />
          <div className="w-10/12 sm:w-full">
            <Navbar />

            <div className="h-[calc(100%-112px)] flex flex-col">
              {/* {user_data ? (
                                <PDFViewer className='h-full'>
                                    <PdfTemplate user={user_data} />
                                </PDFViewer>
                            ) : (
                                <div className="flex justify-center items-center h-full">
                                    <CircularProgress />
                                </div>
                            )} */}
              {user_data ? (
                <div className="flex flex-col p-5">
                  <div className="flex justify-center mb-2">
                    <span className="text-2xl font-semibold">
                      {user_data.description}
                    </span>
                  </div>
                  <div className="flex flex-col gap-5 border-b-2 border-zinc-200 pb-5 mb-5">
                    <div>
                      <span className="text-2xl font-semibold">
                        Dados do Usuário
                      </span>
                    </div>
                    <div className="flex flex-col gap-5 ">
                      {localStorage.getItem("is_admin") ? (
                        <div>
                          <div className="flex justify-center mb-2">
                            <div className="text-2xl">
                              <span className="font-semibold">Status: </span>
                              <span
                                className={
                                  statusUser[user_data.user_stat] +
                                  " font-medium"
                                }
                              >
                                {statusUser[user_data.user_stat]}
                              </span>
                            </div>
                          </div>
                          <div className="flex gap-5 justify-center m-4">
                            <div>
                              <Button
                                sx={{
                                  backgroundColor: "green",
                                  "&:hover": {
                                    backgroundColor: "rgba(50, 128, 0)",
                                  },
                                }}
                                variant="contained"
                                endIcon={<CheckIcon />}
                                onClick={() =>
                                  handleStatus({
                                    user_id: user_data.user_id,
                                    status: 1,
                                  })
                                }
                              >
                                Aprovar
                              </Button>
                            </div>
                            <div>
                              <Button
                                sx={{
                                  backgroundColor: "rgb(177, 115, 0)",
                                  "&:hover": {
                                    backgroundColor: "rgb(112, 73, 1)",
                                  },
                                }}
                                variant="contained"
                                endIcon={<AccessTimeIcon />}
                                onClick={() =>
                                  handleStatus({
                                    user_id: user_data.user_id,
                                    status: 2,
                                  })
                                }
                              >
                                Suspender
                              </Button>
                            </div>
                            <div>
                              <Button
                                sx={{
                                  backgroundColor: "rgb(107, 10, 10)",
                                  "&:hover": {
                                    backgroundColor: "rgb(107, 10, 10)",
                                  },
                                }}
                                variant="contained"
                                endIcon={<BlockIcon />}
                                onClick={() =>
                                  handleStatus({
                                    user_id: user_data.user_id,
                                    status: 3,
                                  })
                                }
                              >
                                Bloquear
                              </Button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      <div className="flex gap-5 text-xl justify-center">
                        <div className="border-r-2 border-b-2 border-iziblue pr-5">
                          <LightTooltip
                            onClose={() =>
                              setCopiedState("Clique aqui para copiar o campo.")
                            }
                            title={copiedState}
                            TransitionComponent={Zoom}
                            placement="top"
                            arrow
                          >
                            <IconButton
                              onClick={() =>
                                copyToClipboard(user_data.username)
                              }
                              aria-label="delete"
                              size="normal"
                            >
                              <ContentCopyIcon fontSize="inherit" />
                            </IconButton>
                          </LightTooltip>
                          <span className="font-semibold">Usuário: </span>
                          <span>{user_data.username}</span>
                        </div>
                        <div className="border-r-2 border-b-2 border-iziblue pr-5">
                          <LightTooltip
                            onClose={() =>
                              setCopiedState("Clique aqui para copiar o campo.")
                            }
                            title={copiedState}
                            TransitionComponent={Zoom}
                            placement="top"
                            arrow
                          >
                            <IconButton
                              onClick={() =>
                                copyToClipboard(user_data.username)
                              }
                              aria-label="delete"
                              size="normal"
                            >
                              <ContentCopyIcon fontSize="inherit" />
                            </IconButton>
                          </LightTooltip>
                          <span className="font-semibold">Senha: </span>
                          <span>{user_data.password}</span>
                        </div>
                      </div>
                      <div className="flex flex-col gap-5">
                        <div className="flex gap-5 flex-wrap">
                          <div className="border-r-2 border-b-2 border-iziblue pr-5">
                            <LightTooltip
                              onClose={() =>
                                setCopiedState(
                                  "Clique aqui para copiar o campo."
                                )
                              }
                              title={copiedState}
                              TransitionComponent={Zoom}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() =>
                                  copyToClipboard(user_data.user_id)
                                }
                                aria-label="delete"
                                size="small"
                              >
                                <ContentCopyIcon fontSize="inherit" />
                              </IconButton>
                            </LightTooltip>

                            <span className="font-semibold">User Id: </span>
                            <span>{user_data.user_id}</span>
                          </div>
                          <div className="border-r-2 border-b-2 border-iziblue pr-5">
                            <LightTooltip
                              onClose={() =>
                                setCopiedState(
                                  "Clique aqui para copiar o campo."
                                )
                              }
                              title={copiedState}
                              TransitionComponent={Zoom}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() =>
                                  copyToClipboard(getTypeAccount(user_data))
                                }
                                aria-label="delete"
                                size="small"
                              >
                                <ContentCopyIcon fontSize="inherit" />
                              </IconButton>
                            </LightTooltip>
                            <span className="font-semibold">
                              Tipo da conta:{" "}
                            </span>
                            <span>{getTypeAccount(user_data)}</span>
                          </div>
                          <div className="border-r-2 border-b-2 border-iziblue pr-5">
                            <LightTooltip
                              onClose={() =>
                                setCopiedState(
                                  "Clique aqui para copiar o campo."
                                )
                              }
                              title={copiedState}
                              TransitionComponent={Zoom}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() =>
                                  copyToClipboard(user_data.user_parent_id)
                                }
                                aria-label="delete"
                                size="small"
                              >
                                <ContentCopyIcon fontSize="inherit" />
                              </IconButton>
                            </LightTooltip>
                            <span className="font-semibold">
                              Id Conta Master:{" "}
                            </span>
                            <span>
                              {user_data.user_parent_id
                                ? user_data.user_parent_id
                                : "Não definido"}
                            </span>
                          </div>
                        </div>
                        <div className="flex gap-5 flex-wrap">
                          <div className="border-r-2 border-b-2 border-iziblue pr-5">
                            <LightTooltip
                              onClose={() =>
                                setCopiedState(
                                  "Clique aqui para copiar o campo."
                                )
                              }
                              title={copiedState}
                              TransitionComponent={Zoom}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() =>
                                  copyToClipboard(user_data.virtual_account)
                                }
                                aria-label="delete"
                                size="small"
                              >
                                <ContentCopyIcon fontSize="inherit" />
                              </IconButton>
                            </LightTooltip>
                            <span className="font-semibold">Id Virtual: </span>
                            <span>{user_data.virtual_account}</span>
                          </div>
                          <div className="border-r-2 border-b-2 border-iziblue pr-5">
                            <LightTooltip
                              onClose={() =>
                                setCopiedState(
                                  "Clique aqui para copiar o campo."
                                )
                              }
                              title={copiedState}
                              TransitionComponent={Zoom}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() =>
                                  copyToClipboard(user_data.entity_id)
                                }
                                aria-label="delete"
                                size="small"
                              >
                                <ContentCopyIcon fontSize="inherit" />
                              </IconButton>
                            </LightTooltip>
                            <span className="font-semibold">Id Entidade: </span>
                            <span>{user_data.entity_id}</span>
                          </div>
                          <div className="border-r-2 border-b-2 border-iziblue pr-5">
                            <LightTooltip
                              onClose={() =>
                                setCopiedState(
                                  "Clique aqui para copiar o campo."
                                )
                              }
                              title={copiedState}
                              TransitionComponent={Zoom}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() =>
                                  copyToClipboard(user_data.pix_key)
                                }
                                aria-label="delete"
                                size="small"
                              >
                                <ContentCopyIcon fontSize="inherit" />
                              </IconButton>
                            </LightTooltip>
                            <span className="font-semibold">Chave pix: </span>
                            <span>{user_data.pix_key}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-5 border-b-2 border-zinc-200 pb-5 mb-5">
                    <div>
                      <span className="text-2xl font-semibold">
                        Tecnologias
                      </span>
                    </div>
                    <div className="flex flex-col gap-5">
                      <div className="flex flex-col gap-5">
                        <div className="flex gap-5 flex-wrap">
                          <div className="border-r-2 border-b-2 border-iziblue pr-5">
                            <LightTooltip
                              onClose={() =>
                                setCopiedState(
                                  "Clique aqui para copiar o campo."
                                )
                              }
                              title={
                                "Clique aqui para adicionar o parâmetro ao campo de criação."
                              }
                              TransitionComponent={Zoom}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() =>
                                  copyToClipboard(user_data.external_token)
                                }
                                aria-label="delete"
                                size="small"
                              >
                                <ContentCopyIcon fontSize="inherit" />
                              </IconButton>
                            </LightTooltip>
                            <span className="font-semibold">
                              Token Api Izipay:{" "}
                            </span>
                            <span>{user_data.external_token}</span>
                          </div>
                          <div className="border-r-2 border-b-2 border-iziblue pr-5">
                            <LightTooltip
                              onClose={() =>
                                setCopiedState(
                                  "Clique aqui para copiar o campo."
                                )
                              }
                              title={copiedState}
                              TransitionComponent={Zoom}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() =>
                                  copyToClipboard(user_data.mk_bank_key)
                                }
                                aria-label="delete"
                                size="small"
                              >
                                <ContentCopyIcon fontSize="inherit" />
                              </IconButton>
                            </LightTooltip>
                            <span className="font-semibold">Key BaaS: </span>
                            <span>{user_data.mk_bank_key}</span>
                          </div>
                          <div className="border-r-2 border-b-2 border-iziblue pr-5">
                            <LightTooltip
                              onClose={() =>
                                setCopiedState(
                                  "Clique aqui para copiar o campo."
                                )
                              }
                              title={copiedState}
                              TransitionComponent={Zoom}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() =>
                                  copyToClipboard(user_data.mk_bank_secret)
                                }
                                aria-label="delete"
                                size="small"
                              >
                                <ContentCopyIcon fontSize="inherit" />
                              </IconButton>
                            </LightTooltip>
                            <span className="font-semibold">Secret BaaS: </span>
                            <span>{user_data.mk_bank_secret}</span>
                          </div>
                        </div>
                        <div className="flex gap-5 flex-wrap">
                          <div className="border-r-2 border-b-2 border-iziblue pr-5">
                            <LightTooltip
                              onClose={() =>
                                setCopiedState(
                                  "Clique aqui para copiar o campo."
                                )
                              }
                              title={copiedState}
                              TransitionComponent={Zoom}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() =>
                                  copyToClipboard(
                                    user_data.confirm_payment_webhook_url
                                  )
                                }
                                aria-label="delete"
                                size="small"
                              >
                                <ContentCopyIcon fontSize="inherit" />
                              </IconButton>
                            </LightTooltip>
                            <span className="font-semibold">
                              Webhook depósito:{" "}
                            </span>
                            <span>{user_data.confirm_payment_webhook_url}</span>
                          </div>
                          <div className="border-r-2 border-b-2 border-iziblue pr-5">
                            <LightTooltip
                              onClose={() =>
                                setCopiedState(
                                  "Clique aqui para copiar o campo."
                                )
                              }
                              title={copiedState}
                              TransitionComponent={Zoom}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() =>
                                  copyToClipboard(
                                    user_data.confirm_cashout_webhook_url ||
                                      user_data.confirm_cashout_webhook_url ==
                                        ""
                                      ? user_data.confirm_cashout_webhook_url
                                      : "Não definido"
                                  )
                                }
                                aria-label="delete"
                                size="small"
                              >
                                <ContentCopyIcon fontSize="inherit" />
                              </IconButton>
                            </LightTooltip>
                            <span className="font-semibold">
                              Webhook saque:{" "}
                            </span>
                            <span>
                              {user_data.confirm_cashout_webhook_url ||
                              user_data.confirm_cashout_webhook_url == ""
                                ? user_data.confirm_cashout_webhook_url
                                : "Não definido"}
                            </span>
                          </div>
                          <div className="border-r-2 border-b-2 border-iziblue pr-5">
                            <LightTooltip
                              onClose={() =>
                                setCopiedState(
                                  "Clique aqui para copiar o campo."
                                )
                              }
                              title={copiedState}
                              TransitionComponent={Zoom}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() =>
                                  copyToClipboard(
                                    user_data.confirm_documents_webhook_url
                                      ? user_data.confirm_documents_webhook_url
                                      : "Não definido"
                                  )
                                }
                                aria-label="delete"
                                size="small"
                              >
                                <ContentCopyIcon fontSize="inherit" />
                              </IconButton>
                            </LightTooltip>
                            <span className="font-semibold">
                              Webhook documentos:{" "}
                            </span>
                            <span>
                              {user_data.confirm_documents_webhook_url
                                ? user_data.confirm_documents_webhook_url
                                : "Não definido"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* */}
                    {/* <button onClick={handleclick(user_data)}>Click</button> */}
                  </div>
                  <div className="flex flex-col pb-5 mb-5 border-b-2 border-zinc-200">
                    <div>
                      <span className="text-xl font-semibold">Utilidades</span>
                    </div>
                    <div className="flex gap-5 mt-5">
                      <div>
                        <Button
                          sx={{
                            backgroundColor:
                              scriptState == "script" ? "#ca4838" : "#283650",
                            "&:hover": {
                              backgroundColor:
                                scriptState == "script" ? "#ca4838" : "#ca4838", // New hover color if variable is true
                            },
                          }}
                          variant="contained"
                          endIcon={<CreateIcon />}
                          onClick={() => handleScript("script")}
                        >
                          Gerador Script
                        </Button>
                      </div>
                      <div>
                        <Button
                          sx={{
                            backgroundColor:
                              scriptState == "payload" ? "#ca4838" : "#283650",
                            "&:hover": {
                              backgroundColor:
                                scriptState == "payload"
                                  ? "#ca4838"
                                  : "#ca4838", // New hover color if variable is true
                            },
                          }}
                          variant="contained"
                          endIcon={<CreateIcon />}
                          onClick={() => handleScript("payload")}
                        >
                          Exibir Payload
                        </Button>
                      </div>
                      <div>
                        <Button
                          sx={{
                            backgroundColor:
                              scriptState == "webhook" ? "#ca4838" : "#283650",
                            "&:hover": {
                              backgroundColor:
                                scriptState == "webhook"
                                  ? "#ca4838"
                                  : "#ca4838", // New hover color if variable is true
                            },
                          }}
                          variant="contained"
                          endIcon={<CreateIcon />}
                          onClick={() => handleScript("webhook")}
                        >
                          Atalhos Webhook
                        </Button>
                      </div>
                      {statusUser[user_data.user_stat] ? (
                        <div>
                          <Button
                            sx={{
                              backgroundColor:
                                scriptState == "documents"
                                  ? "#ca4838"
                                  : "#283650",
                              "&:hover": {
                                backgroundColor:
                                  scriptState == "documents"
                                    ? "#ca4838"
                                    : "#ca4838", // New hover color if variable is true
                              },
                            }}
                            variant="contained"
                            endIcon={<CreateIcon />}
                            onClick={() => handleScript("documents")}
                          >
                            Exibir Documentos
                          </Button>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div>
                        <PDFDownloadLink
                          document={<PdfTemplate user={user_data} />}
                          fileName={
                            user_data.description.replace(" ", "_") +
                            "_Prod_Account.pdf"
                          }
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              <div className="flex justify-center items-center h-full">
                                <CircularProgress />
                              </div>
                            ) : (
                              <Button
                                sx={{
                                  backgroundColor: "#283650",
                                  "&:hover": {
                                    backgroundColor: "#ca4838", // New hover color if variable is true
                                  },
                                }}
                                variant="contained"
                                endIcon={<AccountBoxIcon />}
                              >
                                Gerar Credenciais
                              </Button>
                            )
                          }
                        </PDFDownloadLink>
                      </div>
                    </div>
                    <div
                      className={
                        scriptState == "script"
                          ? " flex flex-col w-full h-full gap-5 animated overflow-hidden mt-5"
                          : "flex flex-col w-full h-0 gap-5 animated overflow-hidden"
                      }
                    >
                      <div className="flex flex-col gap-2">
                        <div className="font-semibold cursor-default">
                          <LightTooltip
                            title="Clique nos parâmetros abaixo para adicioná-los ao texto."
                            TransitionComponent={Zoom}
                            placement="bottom"
                            arrow
                          >
                            Manual do Script
                            <InfoOutlinedIcon
                              style={{
                                fontSize: "small",
                                verticalAlign: "top",
                              }}
                            />
                          </LightTooltip>
                        </div>
                        <div className="flex flex-wrap">
                          <div
                            onClick={() =>
                              setSearchVal(searchVal + " {{username}}")
                            }
                            className=" hover:bg-slate-200 mb-2 mr-4 text-sm p-2 rounded-lg cursor-pointer"
                          >
                            <span>Usuário : {"{{username}}"}</span>
                          </div>
                          <div
                            onClick={() =>
                              setSearchVal(searchVal + " {{password}}")
                            }
                            className="hover:bg-slate-200 mb-2 mr-4 text-sm p-2 rounded-lg cursor-pointer"
                          >
                            <span>Senha : {"{{password}}"}</span>
                          </div>
                          <div
                            onClick={() =>
                              setSearchVal(searchVal + " {{user_id}}")
                            }
                            className="hover:bg-slate-200 mb-2 mr-4 text-sm p-2 rounded-lg cursor-pointer"
                          >
                            <span>User ID : {"{{user_id}}"}</span>
                          </div>
                          <div
                            onClick={() =>
                              setSearchVal(searchVal + " {{virtual_account}}")
                            }
                            className="hover:bg-slate-200 mb-2 mr-4 text-sm p-2 rounded-lg cursor-pointer"
                          >
                            <span>Id Virtual : {"{{virtual_account}}"}</span>
                          </div>
                          <div
                            onClick={() =>
                              setSearchVal(searchVal + " {{entity_id}}")
                            }
                            className="hover:bg-slate-200 mb-2 mr-4 text-sm p-2 rounded-lg cursor-pointer"
                          >
                            <span>Id Entidade : {"{{entity_id}}"}</span>
                          </div>
                          <div
                            onClick={() =>
                              setSearchVal(searchVal + " {{pix_key}}")
                            }
                            className="hover:bg-slate-200 mb-2 mr-4 text-sm p-2 rounded-lg cursor-pointer"
                          >
                            <span>Chave pix : {"{{pix_key}}"}</span>
                          </div>
                          <div
                            onClick={() =>
                              setSearchVal(searchVal + " {{external_token}}")
                            }
                            className="hover:bg-slate-200 mb-2 mr-4 text-sm p-2 rounded-lg cursor-pointer"
                          >
                            <span>
                              Token Api Izipay : {"{{external_token}}"}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                              setSearchVal(searchVal + " {{mk_bank_key}}")
                            }
                            className="hover:bg-slate-200 mb-2 mr-4 text-sm p-2 rounded-lg cursor-pointer"
                          >
                            <span>Key BaaS : {"{{mk_bank_key}}"}</span>
                          </div>
                          <div
                            onClick={() =>
                              setSearchVal(searchVal + " {{mk_bank_secret}}")
                            }
                            className="hover:bg-slate-200 mb-2 mr-4 text-sm p-2 rounded-lg cursor-pointer"
                          >
                            <span>Secret BaaS : {"{{mk_bank_secret}}"}</span>
                          </div>
                          <div
                            onClick={() =>
                              setSearchVal(
                                searchVal + " {{confirm_payment_webhook_url}}"
                              )
                            }
                            className="hover:bg-slate-200 mb-2 mr-4 text-sm p-2 rounded-lg cursor-pointer"
                          >
                            <span>
                              Webhook depósito :{" "}
                              {"{{confirm_payment_webhook_url}}"}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                              setSearchVal(
                                searchVal + " {{confirm_cashout_webhook_url}}"
                              )
                            }
                            className="hover:bg-slate-200 mb-2 mr-4 text-sm p-2 rounded-lg cursor-pointer"
                          >
                            <span>
                              Webhook saque :{" "}
                              {"{{confirm_cashout_webhook_url}}"}
                            </span>
                          </div>
                          <div
                            onClick={() =>
                              setSearchVal(
                                searchVal + " {{confirm_documents_webhook_url}}"
                              )
                            }
                            className="hover:bg-slate-200 mb-2 mr-4 text-sm p-2 rounded-lg cursor-pointer"
                          >
                            <span>
                              Webhook documentos :{" "}
                              {"{{confirm_documents_webhook_url}}"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex w-full gap-5">
                        <TextField
                          label="Crie seu script"
                          multiline
                          minRows={8}
                          onChange={(e) => {
                            setSearchVal(e.target.value);
                          }}
                          value={searchVal}
                          className="h-0  w-6/12"
                          variant="filled"
                        />
                        <div className=" self-center">
                          <LightTooltip
                            onClose={() =>
                              setCopiedStateScript(
                                "Clique aqui para gerar o script e copiar o resultado."
                              )
                            }
                            title={copiedStateScript}
                            TransitionComponent={Zoom}
                            placement="bottom"
                            arrow
                          >
                            <Button
                              sx={{ padding: "12px 22px" }}
                              variant="contained"
                              onClick={handleclick(user_data)}
                            >
                              <SendIcon />
                            </Button>
                          </LightTooltip>
                        </div>
                        <TextField
                          label="Resultado"
                          multiline
                          minRows={8}
                          value={resultVal}
                          className=" w-6/12"
                          variant="filled"
                        />
                      </div>
                    </div>
                    <div
                      className={
                        scriptState == "payload"
                          ? " flex flex-col w-full h-full gap-5 animated overflow-hidden mt-5 bg-zinc-200 p-5 rounded-lg"
                          : "bg-zinc-200 rounded-lg flex flex-col w-full h-0 gap-5 animated overflow-hidden"
                      }
                    >
                      <div className="flex w-full gap-5 max-w-full">
                        <pre
                          dangerouslySetInnerHTML={{
                            __html: syntaxHighlight(
                              JSON.stringify(filteredJson, undefined, 4)
                            ),
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        scriptState == "webhook"
                          ? " flex flex-col w-full h-full gap-1 animated overflow-hidden mt-5"
                          : "flex flex-col w-full h-0 gap-5 animated overflow-hidden"
                      }
                    >
                      <div className="flex flex-col gap-2">
                        <div className="font-semibold cursor-default">
                          <LightTooltip
                            title="Clique nos na chamada deseja para gerar o Curl."
                            TransitionComponent={Zoom}
                            placement="bottom"
                            arrow
                          >
                            Atalhos para Webhooks
                            <InfoOutlinedIcon
                              style={{
                                fontSize: "small",
                                verticalAlign: "top",
                              }}
                            />
                          </LightTooltip>
                        </div>
                        <div className="flex flex-wrap">
                          <div
                            onClick={() =>
                              handleWebHook(user_data, "qr_code_generate")
                            }
                            className=" hover:bg-slate-200 mb-2 mr-4 text-sm p-2 rounded-lg cursor-pointer"
                          >
                            <span>Qr Code Generate</span>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          scriptState == "webhook"
                            ? " flex flex-col w-full h-full gap-5 animated overflow-hidden bg-zinc-200 p-5 rounded-lg"
                            : "bg-zinc-200 rounded-lg flex flex-col w-full h-0 gap-5 animated overflow-hidden"
                        }
                      >
                        <pre className=" text-lime-600">{Webhook}</pre>
                      </div>
                    </div>

                    <div
                      ref={divRef}
                      className={
                        scriptState == "documents"
                          ? " flex w-full h-full gap-5 animated overflow-hidden mt-5"
                          : "flex w-full h-0 gap-5 animated overflow-hidden "
                      }
                    >
                      <div className="flex w-full gap-5">
                        <div className="w-full flex flex-col gap-5">
                          <div>
                            <span className="text-2xl font-semibold">
                              Documentos
                            </span>
                          </div>
                          <div className="flex flex-col gap-2">
                            <div className="flex flex-col gap-5">
                              <div className="flex gap-5 text-xl">
                                <div>
                                  <span>Status: </span>
                                  <span
                                    className={
                                      statusUser[user_data.user_stat] ==
                                      "PENDING"
                                        ? " text-amber-600 font-semibold"
                                        : " text-lime-600 font-semibold"
                                    }
                                  >
                                    {statusUser[user_data.user_stat]}
                                  </span>
                                </div>
                              </div>
                              <div className="flex gap-5 flex-wrap">
                                <div className="border-r-2 border-b-2 border-iziblue pr-5">
                                  <span className="font-semibold">Nome: </span>
                                  <span>
                                    {user_data.name
                                      ? user_data.name
                                      : "Não definido"}
                                  </span>
                                </div>
                                <div className="border-r-2 border-b-2 border-iziblue pr-5">
                                  <span className="font-semibold">CPF: </span>
                                  <span>
                                    {user_data.cpf
                                      ? user_data.cpf
                                      : "Não definido"}
                                  </span>
                                </div>
                                <div className="border-r-2 border-b-2 border-iziblue pr-5">
                                  <span className="font-semibold">
                                    Nascimento:{" "}
                                  </span>
                                  <span>
                                    {dayjs(user_data.birth_date).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </span>
                                </div>
                                <div className="border-r-2 border-b-2 border-iziblue pr-5">
                                  <span className="font-semibold">Email: </span>
                                  <span>
                                    {user_data.email
                                      ? user_data.email
                                      : "Não definido"}
                                  </span>
                                </div>
                                <div className="border-r-2 border-b-2 border-iziblue pr-5">
                                  <span className="font-semibold">
                                    Telefone:{" "}
                                  </span>
                                  <span>
                                    {user_data.phone
                                      ? user_data.phone
                                      : "Não definido"}
                                  </span>
                                </div>
                              </div>
                              <div className="flex gap-5 flex-wrap">
                                <div className="border-r-2 border-b-2 border-iziblue pr-5">
                                  <span className="font-semibold">
                                    Endereço:{" "}
                                  </span>
                                  <span>
                                    {user_data.street +
                                      ", " +
                                      user_data.number +
                                      ", " +
                                      user_data.city +
                                      ", " +
                                      user_data.state +
                                      ", " +
                                      user_data.zip_code}
                                  </span>
                                </div>
                                <div className="border-r-2 border-b-2 border-iziblue pr-5">
                                  <span className="font-semibold">
                                    Chave Pix:{" "}
                                  </span>
                                  <span>
                                    {user_data.pix_key
                                      ? user_data.pix_key
                                      : "Não definido"}
                                  </span>
                                </div>
                              </div>
                              <div className="flex gap-5 flex-wrap">
                                <div className="border-r-2 border-b-2 border-iziblue pr-5">
                                  <span className="font-semibold">CNPJ: </span>
                                  <span>
                                    {user_data.cnpj
                                      ? user_data.cnpj
                                      : "Não definido"}
                                  </span>
                                </div>
                                <div className="border-r-2 border-b-2 border-iziblue pr-5">
                                  <span className="font-semibold">
                                    Documento:{" "}
                                  </span>
                                  <span>
                                    {user_data.document_type
                                      ? user_data.document_type
                                      : "Não definido"}
                                  </span>
                                </div>
                                <div className="border-r-2 border-b-2 border-iziblue pr-5">
                                  <span className="font-semibold">
                                    Número Documento:{" "}
                                  </span>
                                  <span>
                                    {user_data.document_number
                                      ? user_data.document_number
                                      : "Não definido"}
                                  </span>
                                </div>
                                <div className="border-r-2 border-b-2 border-iziblue pr-5">
                                  <span className="font-semibold">
                                    Autoridade Emissora:{" "}
                                  </span>
                                  <span>
                                    {user_data.issuing_authority
                                      ? user_data.issuing_authority
                                      : "Não definido"}
                                  </span>
                                </div>
                              </div>
                              <div>
                                <span className="text-2xl font-semibold">
                                  Fotos Documento
                                </span>
                              </div>
                              <div className="w-full flex gap-5">
                                <figure className="w-1/2">
                                  <img
                                    className="w-full"
                                    src={user_data.document_photo_content}
                                    alt=""
                                  />
                                  <figcaption>Documento</figcaption>
                                </figure>
                                <figure className="w-1/2">
                                  <img
                                    className="w-full"
                                    src={
                                      user_data.user_with_document_photo_content
                                    }
                                    alt=""
                                  />
                                  <figcaption>Documento</figcaption>
                                </figure>
                              </div>
                              {user_data.cnpj ? (
                                <div>
                                  <div>
                                    <span className="text-2xl font-semibold">
                                      Fotos Cnpj
                                    </span>
                                  </div>
                                  <div className="w-full flex flex-col gap-5">
                                    <div className="flex gap-5">
                                      <figure className="w-1/2">
                                        <img
                                          className="w-full"
                                          src={user_data.document_photo_content}
                                          alt=""
                                        />
                                        <figcaption>Cnpj</figcaption>
                                      </figure>
                                      <figure className="w-1/2">
                                        <img
                                          className="w-full"
                                          src={
                                            user_data.user_with_document_photo_content
                                          }
                                          alt=""
                                        />
                                        <figcaption>Contrato Social</figcaption>
                                      </figure>
                                    </div>
                                    <div className="flex w-full justify-center">
                                      <figure className="w-1/2">
                                        <img
                                          className="w-full"
                                          src={
                                            user_data.user_with_document_photo_content
                                          }
                                          alt=""
                                        />
                                        <figcaption>Contrato Social</figcaption>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex justify-center items-center h-full">
                  <CircularProgress />
                </div>
              )}
            </div>
          </div>
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default UserProfile;
