/* eslint-disable no-constant-condition */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
/* import "./list.scss"; */
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useContext, useEffect, useState } from "react";
import Datatable from "../../components/datatable/Datatable.jsx";
import DatatableMobile from "../../components/datatable/DatatableMobile.jsx";
import Navbar from "../../components/navbar/Navbar";
import "../../components/navbar/navbar.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { DarkModeContext } from "../../context/darkModeContext.jsx"; // Import the DarkModeContext
// import { downloadCSV } from "../../services/TransactionsService";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useLocation } from "react-router-dom";
// import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import i18n from "i18next";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { QueryClient, QueryClientProvider } from "react-query";
import { useMediaQuery } from "react-responsive";

const queryClient = new QueryClient();
const SearchBar = ({ onFilterData }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  if (isMobile) {
    import("./listmobile.scss");
  } else {
    import("./list.scss");
  }
  if (isMobile) {
    const changeLanguage = (newLanguage) => {
      i18n.changeLanguage(newLanguage, (err, t) => {
        if (err)
          return console.log("Something went wrong loading language:", err);
      });
    };

    const LightTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: theme.shadows[1],
        fontSize: 15,
      },
    }));
    const { t } = useTranslation();
    const { darkMode, dispatch } = useContext(DarkModeContext);
    const [filterText, setFilterText] = React.useState("");
    const [status, setStatus] = React.useState(t("Sem Filtro"));
    const [movementType, setMovementType] = React.useState(t("Sem Filtro"));
    const [date, setDate] = React.useState(null);
    const [downloading, setDownloading] = useState(true);
    const [searchType, setSearchType] = useState("");
    const [inputDisabled, setInputdisabled] = useState(true);
    const [inputValue, setInputValue] = useState("");
    const location = useLocation();
    const [chips, setChips] = useState([]);
    const [appendValue, setAppend] = useState("");
    const handleKeyPress = (event) => {
      // Check if the pressed key is Enter (key code 13)
      if (event.key === "Enter") {
        const chipval = searchType.replaceAll("_", " ") + ": " + filterText;
        setChips([...chips, chipval]);
        setDownloading(false);
        // Call your function here
        handleSearch();
      }
    };
    const handleSearchTypeChange = (event) => {
      event.target.value == ""
        ? (setInputdisabled(true), clearSearch())
        : setInputdisabled(false);
      setSearchType(event.target.value);
      setFilterText("");
      /* setStatus(undefined);
      setMovementType(undefined);
      setDate(new Date()); */
      setDownloading(true);
    };
    const handleStatusChange = (event) => {
      setStatus(event.target.value);
      setChips([...chips, event.target.value.trim()]);
      setAppend("");
    };
    const handleDeleteChip = (index, chip) => {
      // eslint-disable-next-line no-constant-condition
      chip == "PAID" || "PAID" || "FAILED" || "REFUNDED"
        ? setStatus(t("Sem Filtro"))
        : "";
      chip == "CASH-IN" || "CASH-OUT" || "TEF"
        ? setMovementType(t("Sem Filtro"))
        : "";
      const conditions = ["end to end id", "order id", "transaction id"];
      conditions.some((el) => chip.includes(el))
        ? (setFilterText(""), setSearchType(""))
        : "";
      dayjs(chip, "DD/MM/YYYY", true).isValid() ? setDate(null) : "";
      const updatedChips = [...chips];
      updatedChips.splice(index, 1);
      setChips(updatedChips);
    };
    useEffect(() => {
      filterText == "" ? clearSearch() : "";
    }, [filterText]);
    useEffect(() => {
      status != t("Sem Filtro")
        ? (handleSearch(), setDownloading(false))
        : clearSearch();
    }, [status]);
    const handleMovementTypeChange = (event) => {
      setMovementType(event.target.value);
      setChips([...chips, event.target.value.trim()]);
      setAppend("");
    };
    useEffect(() => {
      movementType != t("Sem Filtro")
        ? (handleSearch(), setDownloading(false))
        : clearSearch();
    }, [movementType]);
    useEffect(() => {
      const stringDate = dayjs(date).format("DD/MM/YYYY");
      date != null
        ? (handleSearch(),
          setDownloading(false),
          setChips([...chips, stringDate]),
          setAppend(""))
        : clearSearch();
    }, [date]);

    const clearSearch = () => {
      setSearchType("");
      setFilterText(filterText);
      setStatus(status);
      setMovementType(movementType);
      setDate(date);
      if (
        status == t("Sem Filtro") &&
        movementType == t("Sem Filtro") &&
        date == null &&
        filterText == ""
      ) {
        setDownloading(true);
      }
      onFilterData();
    };

    const handleDownloadCSV = async () => {
      const link = document.createElement("a");
      link.href = `../../../public/cvsFiles/transactions.csv`;
    
      document.body.appendChild(link);
      link.click();
    
      document.body.removeChild(link);
      
    };
    const handleSearch = async () => {
      try {
        const filters = {};
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (searchType && filterText) filters[searchType] = filterText;
        if (filterText) return onFilterData(filters);
        if (movementType != t("Sem Filtro"))
          filters["movement_type"] = movementType;
        if (date) filters["date"] = `${dayjs(date).format("YYYY-MM-DD")}`;
        if (status != t("Sem Filtro")) filters["status"] = status;
        onFilterData(filters);
      } catch (error) {
        console.error(error);
      }
    };

    return (
      <div className="w-full flex flex-col">
        <div className=" px-4 flex flex-col gap-3 w-full">
          <div className="w-12/12 flex">
            <div className="dropdown w-1/2">
              <Box sx={{}}>
                <FormControl fullWidth>
                  <InputLabel
                    id="status_label"
                    style={{
                      fontWeight: "600",
                      fontSize: "10px",
                      bottom: "-7px",
                      left: "-4px",
                      transformOrigin: "center",
                    }}
                  >
                    Status
                  </InputLabel>
                  <Select
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    size="small"
                    labelId="status"
                    id="status"
                    value={status ?? t("Sem Filtro")}
                    label="Status"
                    disabled={!!filterText}
                    onChange={handleStatusChange}
                    sx={{
                      fontSize: "10px",
                    }}
                  >
                    <MenuItem value={t("Sem Filtro")} selected>
                      {t("Sem Filtro")}
                    </MenuItem>
                    <MenuItem value={"PAID"}>Paid</MenuItem>
                    <MenuItem value={"GENERATED"}>Generated</MenuItem>
                    <MenuItem value={"FAILED"}>Failed</MenuItem>
                    <MenuItem value={"REFUNDED"}>Refunded</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="dropdown w-1/2">
              <Box sx={{}}>
                <FormControl fullWidth>
                  <InputLabel
                    id="movement_type_label"
                    style={{
                      fontWeight: "600",
                      fontSize: "10px",
                      bottom: "-7px",
                      left: "-4px",
                      transformOrigin: "center",
                    }}
                  >
                    {t("Descrição")}
                  </InputLabel>
                  <Select
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    size="small"
                    labelId="movement_type"
                    id="movement_type"
                    value={movementType ?? t("Sem Filtro")}
                    label="movement_type"
                    disabled={!!filterText}
                    onChange={handleMovementTypeChange}
                    sx={{
                      fontSize: "10px",
                    }}
                  >
                    <MenuItem value={t("Sem Filtro")} selected>
                      {t("Sem Filtro")}
                    </MenuItem>
                    <MenuItem value={"CASH-IN"}>Cash-in</MenuItem>
                    <MenuItem value={"CASH-OUT"}>Cash-out</MenuItem>
                    <MenuItem value={"TEF"}>TEF</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
          <div className="datepickers w-12/12 flex justify-between">
            <div className="flex gap-1">
              <div className=" w-4/5 ">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <MobileDatePicker
                    disableScrollLock={true}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    slotProps={{
                      textField: {
                        error: false,
                        size: "small",
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography
                                style={{ fontWeight: "500", fontSize: "10px" }}
                              >
                                Dia:
                              </Typography>
                            </InputAdornment>
                          ),
                        },
                      },
                    }}
                    sx={{
                      width: "100%",
                      fontSize: "10px",
                    }}
                    disabled={!!filterText}
                    value={date}
                    onChange={(newValue) => setDate(newValue)}
                  />
                </LocalizationProvider>
              </div>
              <div className="hidden w-2/5">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <MobileDatePicker
                    className="dpicker"
                    fontSize={10}
                    slotProps={{
                      textField: {
                        error: false,
                        size: "small",
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography
                                style={{ fontWeight: "500", fontSize: "10px" }}
                              >
                                Até:
                              </Typography>
                            </InputAdornment>
                          ),
                        },
                      },
                    }}
                    sx={{
                      width: "100%",
                    }}
                    disabled={!!filterText}
                    value={date}
                    readOnly
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className=" w-1/5 h-full flex gap-3">
              <Button
                variant="contained"
                onClick={handleDownloadCSV}
                disabled={!!downloading} // disable button when downloading
                style={{
                  color: downloading ? "lightgray" : "white", // change color when disabled
                  backgroundColor: downloading ? "#f0f0f0" : "green", // change backgroundColor when disabled
                  borderColor: downloading ? "gray" : "green", // change borderColor when disabled
                  minHeight: "1px",
                  minWidth: "1px",
                  marginRight: "1px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <DownloadIcon />
              </Button>
              <LightTooltip
                title="Máximo de 1 dia. Em breve, disponível para 7 dias."
                TransitionComponent={Zoom}
                placement="left"
              >
                <InfoOutlinedIcon
                  style={{ fontSize: "small", verticalAlign: "top" }}
                />
              </LightTooltip>
            </div>
          </div>
          <div className="w-1/12 flex gap-2 place-content-center">
            {/* <div className='w-full button'>
              <Button
                endIcon={<SendIcon />}
                variant="contained"
                onClick={handleSearch}
                disabled={false}
                style={{
                  color: 'white',
                  backgroundColor: '#ff442b',
                  borderColor: '#ff442b',
                  minHeight: '50px',
                  minWidth: '150px',
                  width: '100%',
                  height: '100%',
                }}>{t('SEARCH')}</Button>
            </div>
            <div className='w-full button'>
              <Button
                startIcon={<DeleteIcon />}
                variant="contained"
                onClick={clearSearch}
                style={{
                  color: 'white',
                  backgroundColor: 'gray',
                  borderColor: '#ff442b',
                  minHeight: '50px',
                  minWidth: '150px',
                  marginRight: '10px',
                  width: '100%',
                  height: '100%',
                }}> {t('CLEAR')}</Button>
            </div> */}
          </div>
        </div>
      </div>
    );
  } else {
    const changeLanguage = (newLanguage) => {
      i18n.changeLanguage(newLanguage, (err, t) => {
        if (err)
          return console.log("Something went wrong loading language:", err);
      });
    };

    const LightTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: theme.shadows[1],
        fontSize: 15,
      },
    }));
    const { t } = useTranslation();
    const { darkMode, dispatch } = useContext(DarkModeContext);
    const [filterText, setFilterText] = React.useState("");
    const [status, setStatus] = React.useState(t("Sem Filtro"));
    const [movementType, setMovementType] = React.useState(t("Sem Filtro"));
    const [date, setDate] = React.useState(null);
    const [downloading, setDownloading] = useState(true);
    const [searchType, setSearchType] = useState("");
    const [inputDisabled, setInputdisabled] = useState(true);
    const [inputValue, setInputValue] = useState("");
    const location = useLocation();
    const [chips, setChips] = useState([]);
    const [appendValue, setAppend] = useState("");
    const handleKeyPress = (event) => {
      // Check if the pressed key is Enter (key code 13)
      if (event.key === "Enter") {
        const chipval = searchType.replaceAll("_", " ") + ": " + filterText;
        setChips([...chips, chipval]);
        setDownloading(false);
        // Call your function here
        handleSearch();
      }
    };
    const handleSearchTypeChange = (event) => {
      event.target.value == ""
        ? (setInputdisabled(true), clearSearch())
        : setInputdisabled(false);
      setSearchType(event.target.value);
      setFilterText("");
      /* setStatus(undefined);
      setMovementType(undefined);
      setDate(new Date()); */
      setDownloading(true);
    };
    const handleStatusChange = (event) => {
      setStatus(event.target.value);
      const updatedChips = [...chips];
      updatedChips.map((chip, index) => {
        if (
          chip === "PAID" ||
          chip === "GENERATED" ||
          chip === "FAILED" ||
          chip === "REFUNDED"
        ) {
          chips.splice(index, 1);
          setChips(updatedChips);
        }
      });
      setChips([...chips, event.target.value.trim()]);
      setAppend("");
    };

    const handleDeleteChip = (index, chip) => {
      const updatedChips = [...chips];
      updatedChips.splice(index, 1);
      setChips(updatedChips);
      chip === "PAID" ||
      chip === "GENERATED" ||
      chip === "FAILED" ||
      chip === "REFUNDED"
        ? setStatus(t("Sem Filtro"))
        : "";
      chip === "CASH-IN" || chip === "CASH-OUT" || chip === "TEF"
        ? setMovementType(t("Sem Filtro"))
        : "";
      const conditions = ["end to end id", "order id", "transaction id"];
      conditions.some((el) => chip.includes(el))
        ? (setFilterText(""), setSearchType(""))
        : "";
      dayjs(chip, "DD/MM/YYYY", true).isValid() ? setDate(null) : "";
    };
    useEffect(() => {
      filterText == "" ? clearSearch() : "";
    }, [filterText]);
    useEffect(() => {
      status != t("Sem Filtro") || movementType != t("Sem Filtro")
        ? (handleSearch(), setDownloading(false))
        : clearSearch();
    }, [status, movementType]);
    /* useEffect(() => {
      movementType != t('Sem Filtro') ? (handleSearch(), setDownloading(false)) : clearSearch();
    }, [movementType]); */

    const handleMovementTypeChange = (event) => {
      setMovementType(event.target.value);
      const updatedChips = [...chips];
      updatedChips.map((chip, index) => {
        if (chip === "CASH-IN" || chip === "CASH-OUT" || chip === "TEF") {
          chips.splice(index, 1);
          setChips(updatedChips);
        }
      });
      setChips([...chips, event.target.value.trim()]);
      setAppend("");
    };

    useEffect(() => {
      movementType != t("Sem Filtro")
        ? (handleSearch(), setDownloading(false))
        : clearSearch();
    }, [movementType]);
    useEffect(() => {
      const stringDate = dayjs(date).format("DD/MM/YYYY");
      const updatedChips = [...chips];
      updatedChips.map((chip, index) => {
        if (dayjs(chip, "DD/MM/YYYY", true).isValid()) {
          chips.splice(index, 1);
          setChips(updatedChips);
        }
      });
      date != null
        ? (handleSearch(),
          setDownloading(false),
          setChips([...chips, stringDate]),
          setAppend(""))
        : clearSearch();
    }, [date]);

    const clearSearch = () => {
      setSearchType("");
      setFilterText(filterText);
      setStatus(status);
      setMovementType(movementType);
      setDate(date);
      if (
        status == t("Sem Filtro") &&
        movementType == t("Sem Filtro") &&
        date == null &&
        filterText == ""
      ) {
        setDownloading(true);
      }
      onFilterData();
      handleSearch();
    };

    const handleDownloadCSV = async () => {
        const link = document.createElement("a");
        link.href = `../../../public/cvsFiles/transactions.csv`;
      
        document.body.appendChild(link);
        link.click();
      
        document.body.removeChild(link);
    };
    
    const handleSearch = async () => {
      try {
        const filters = {};
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (searchType && filterText) filters[searchType] = filterText;
        if (filterText) return onFilterData(filters);
        if (movementType != t("Sem Filtro"))
          filters["movement_type"] = movementType;
        if (date) filters["date"] = `${dayjs(date).format("YYYY-MM-DD")}`;
        if (status != t("Sem Filtro")) filters["status"] = status;
        onFilterData(filters);
      } catch (error) {
        console.error(error);
      }
    };

    return (
      <div className="w-full flex flex-col">
        <div className=" px-4 flex gap-3 w-full">
          <div className="w-4/12">
            <div className="w-full">
              <TextField
                sx={{
                  width: "100%",
                }}
                className="textfield"
                id="search_value_input"
                label={<SearchIcon />}
                variant="outlined"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                onKeyDown={handleKeyPress}
                disabled={inputDisabled}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ marginRight: "-14px" }}
                    >
                      <Select
                        size="small"
                        sx={{
                          "&:hover": {
                            "&& fieldset": {
                              border: "1px solid #283650",
                            },
                          },
                          "&:active": {
                            "&& fieldset": {
                              border: "1px solid transparent",
                            },
                          },
                        }}
                        value={searchType}
                        onChange={handleSearchTypeChange}
                        displayEmpty
                        style={{ padding: 0, minWidth: "auto" }} // Reduce padding and minWidth
                      >
                        <MenuItem value="">{t("Sem Filtro")}</MenuItem>
                        <MenuItem
                          className="border-2 border-transparent hover:border-iziblue"
                          value={"order_id"}
                        >
                          Order ID
                        </MenuItem>
                        <MenuItem
                          className="border-2 border-transparent hover:border-iziblue"
                          value={"end_to_end_id"}
                        >
                          End to End ID
                        </MenuItem>
                        <MenuItem
                          className="border-2 border-transparent hover:border-iziblue"
                          value={"transaction_id"}
                        >
                          Transaction ID
                        </MenuItem>
                      </Select>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="w-4/12 flex">
            <div className="dropdown w-1/2">
              <Box sx={{}}>
                <FormControl fullWidth>
                  <InputLabel id="status_label" style={{ fontWeight: "600" }}>
                    Status
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="status"
                    id="status"
                    value={status ?? t("Sem Filtro")}
                    label="Status"
                    disabled={!!filterText}
                    onChange={handleStatusChange}
                  >
                    <MenuItem value={t("Sem Filtro")} selected>
                      {t("Sem Filtro")}
                    </MenuItem>
                    <MenuItem value={"PAID"}>Paid</MenuItem>
                    <MenuItem value={"GENERATED"}>Generated</MenuItem>
                    <MenuItem value={"FAILED"}>Failed</MenuItem>
                    <MenuItem value={"REFUNDED"}>Refunded</MenuItem>
                    <MenuItem value={"EXPIRED"}>Expired</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="dropdown w-1/2">
              <Box sx={{}}>
                <FormControl fullWidth>
                  <InputLabel
                    id="movement_type_label"
                    style={{ fontWeight: "600" }}
                  >
                    {t("Descrição")}
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="movement_type"
                    id="movement_type"
                    value={movementType ?? t("Sem Filtro")}
                    label="movement_type"
                    disabled={!!filterText}
                    onChange={handleMovementTypeChange}
                  >
                    <MenuItem value={t("Sem Filtro")} selected>
                      {t("Sem Filtro")}
                    </MenuItem>
                    <MenuItem value={"CASH-IN"}>Cash-in</MenuItem>
                    <MenuItem value={"CASH-OUT"}>Cash-out</MenuItem>
                    <MenuItem value={"TEF"}>TEF</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
          <div className="datepickers w-3/12">
            <div className="flex w-full gap-1">
              <div className="w-full">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: false,
                        size: "small",
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography style={{ fontWeight: "500" }}>
                                De:
                              </Typography>
                            </InputAdornment>
                          ),
                        },
                      },
                    }}
                    sx={{
                      width: "100%",
                    }}
                    disabled={!!filterText}
                    value={date}
                    onChange={(newValue) => setDate(newValue)}
                  />
                </LocalizationProvider>
              </div>
              {/* <div className=' self-center'>
                  <div>to</div>
                </div> */}
              <div className="w-full">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: false,
                        size: "small",
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography style={{ fontWeight: "500" }}>
                                Até:
                              </Typography>
                            </InputAdornment>
                          ),
                        },
                      },
                    }}
                    sx={{
                      width: "100%",
                    }}
                    disabled={!!filterText}
                    value={date}
                    readOnly
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div className="w-1/12 flex gap-2 place-content-center">
            {/* <div className='w-full button'>
                <Button
                  endIcon={<SendIcon />}
                  variant="contained"
                  onClick={handleSearch}
                  disabled={false}
                  style={{
                    color: 'white',
                    backgroundColor: '#ff442b',
                    borderColor: '#ff442b',
                    minHeight: '50px',
                    minWidth: '150px',
                    width: '100%',
                    height: '100%',
                  }}>{t('SEARCH')}</Button>
              </div>
              <div className='w-full button'>
                <Button
                  startIcon={<DeleteIcon />}
                  variant="contained"
                  onClick={clearSearch}
                  style={{
                    color: 'white',
                    backgroundColor: 'gray',
                    borderColor: '#ff442b',
                    minHeight: '50px',
                    minWidth: '150px',
                    marginRight: '10px',
                    width: '100%',
                    height: '100%',
                  }}> {t('CLEAR')}</Button>
              </div> */}
            <div className=" w-4/6 h-full flex gap-3">
              <Button
                variant="contained"
                onClick={handleDownloadCSV}
                disabled={!!downloading} // disable button when downloading
                style={{
                  color: downloading ? "lightgray" : "white", // change color when disabled
                  backgroundColor: downloading ? "#f0f0f0" : "green", // change backgroundColor when disabled
                  borderColor: downloading ? "gray" : "green", // change borderColor when disabled
                  minHeight: "1px",
                  minWidth: "1px",
                  marginRight: "1px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <DownloadIcon />
              </Button>

              <LightTooltip
                title="Máximo de 1 dia. Em breve, disponível para 7 dias."
                TransitionComponent={Zoom}
                placement="left"
              >
                <InfoOutlinedIcon
                  style={{ fontSize: "large", verticalAlign: "top" }}
                />
              </LightTooltip>
            </div>
          </div>
        </div>
        <div className="px-4 flex gap-3 w-full mt-4">
          {chips.map((chip, index) => (
            <Chip
              key={index}
              label={chip}
              className="border-zinc-400"
              onDelete={() => handleDeleteChip(index, chip)}
              variant="outlined"
              sx={{
                border: "2px solid rgb(161 161 170)",
                "& .MuiChip-label": {
                  fontSize: 15,
                  fontWeight: "500",
                },
              }}
            />
          ))}
        </div>
      </div>
    );
  }
};
const List = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  if (isMobile) {
    import("./listmobile.scss");
  } else {
    import("./list.scss");
  }

  if (isMobile) {
    const { darkMode } = useContext(DarkModeContext); // Get the darkMode value from the context
    const [filterData, setFilterData] = useState({});

    const handleFilterData = (data) => {
      setFilterData(data);
    };
    const theme = createTheme({
      typography: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
      },
      components: {
        MuiDateCalendar: {
          styleOverrides: {
            root: {
              fontSize: "10px",
            },
          },
        },
      },
    });
    return (
      <ThemeProvider theme={theme}>
        <div className={`list ${darkMode ? "dark" : ""}`}>
          <Sidebar />
          <div className="w-10/12 sm:w-full homeContainer">
            <QueryClientProvider client={queryClient}>
              <Navbar />
            </QueryClientProvider>
            <div className="w-full">
              <div className="flex items-center justify-between mt-6 mb-2">
                <SearchBar onFilterData={handleFilterData} />
              </div>
              <DatatableMobile filterData={filterData} />
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  } else {
    const { darkMode } = useContext(DarkModeContext); // Get the darkMode value from the context
    const [filterData, setFilterData] = useState({});

    const handleFilterData = (data) => {
      setFilterData(data);
    };
    const theme = createTheme({
      typography: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
      },
    });
    return (
      <ThemeProvider theme={theme}>
        <div className={`list ${darkMode ? "dark" : ""} w-full`}>
          <Sidebar />
          <div className="w-5/12 sm:w-full homeContainer">
            <QueryClientProvider client={queryClient}>
              <Navbar />
            </QueryClientProvider>
            <div className="w-full">
              <div className="flex items-center justify-between mt-6 mb-2">
                <SearchBar onFilterData={handleFilterData} />
              </div>
              <Datatable filterData={filterData} />
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
};

export default List;
