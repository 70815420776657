/* eslint-disable no-unused-vars */
import * as Sentry from "@sentry/react";
import { format } from "date-fns";
import dayjs from "dayjs";
import { decrypt, encrypt } from "../utils/crypto.mjs";

const baseURL = import.meta.env.VITE_API_URL;
const CACHE_TIMER = 1000 * 59;
let getStatementCache = {};
let getStatementSubCache = {};
let getStatementFeeCache = {};
let getBalanceCache = {};
let getMetricsCache = {};
let getDocumentsCache = {};
let getUsersCache = {};


const checkIfShouldLogout = () => {
  const LOGOUT_TIMER = 1000 * 60 * 60 * 6;
  const currentTime = Date.now();
  const lastlog = localStorage.getItem("last_login");
  if (currentTime - lastlog > LOGOUT_TIMER) {
    localStorage.clear();
    window.location.reload();
  } else {
    const timeRemaining = lastlog - currentTime;
    /* console.log(
      `Time remaining before logout: ${Math.ceil(timeRemaining / 1000)} seconds`
    ); */
  }
};
async function getStatement(
  page = 1,
  limit = 100,
  filters = {},

) {
  try {
    const statementFixo = {
      "transactions": [
        {
          "account_bank_ispb": "",
          "account_bank_name": "",
          "account_branch": "",
          "account_number": "",
          "account_owner_name": "User Demonstração",
          "amount": 170.50,
          "document_number": "",
          "end_to_end_id": "",
          "fee_charged": 0.13,
          "fees_status": "",
          "last_updated_at": "2024-04-24T10:36:45.000",
          "movement_type": "CASH-IN",
          "order_id": "A000A000A000A000A000A000A000A000A00",
          "qr_code_value": "http://faturas.iugu.com/iugu_pix/f57f881f-1a0a-47ce-8320-92b2478f85b5-fa26/test/pay",
          "status": "EXPIRED",
          "transaction_id": "D-00000000-0000-0000-0000-000000000000",
          "user_description": "User Demonstração",
          "user_id": 111
        },
        {
          "account_bank_ispb": "",
          "account_bank_name": "",
          "account_branch": "",
          "account_number": "",
          "account_owner_name": "User Demonstração",
          "amount": 198,
          "document_number": "",
          "end_to_end_id": "",
          "fee_charged": 0.15,
          "fees_status": "",
          "last_updated_at": "2024-04-24T11:15:35.000",
          "movement_type": "CASH-OUT",
          "order_id": "A000A000A000A000A000A000A000A000A00",
          "qr_code_value": "http://faturas.iugu.com/iugu_pix/f57f881f-1a0a-47ce-8320-92b2478f85b5-fa26/test/pay",
          "status": "GENERATED",
          "transaction_id": "D-00000000-0000-0000-0000-000000000000",
          "user_description": "User Demonstração",
          "user_id": 111
        },
        {
          "account_bank_ispb": "",
          "account_bank_name": "",
          "account_branch": "",
          "account_number": "",
          "account_owner_name": "User Demonstração",
          "amount": 89.30,
          "document_number": "",
          "end_to_end_id": "",
          "fee_charged": 0.12,
          "fees_status": "",
          "last_updated_at": "2024-04-24T13:20:50.000",
          "movement_type": "CASH-IN",
          "order_id": "A000A000A000A000A000A000A000A000A00",
          "qr_code_value": "http://faturas.iugu.com/iugu_pix/f57f881f-1a0a-47ce-8320-92b2478f85b5-fa26/test/pay",
          "status": "PAID",
          "transaction_id": "D-00000000-0000-0000-0000-000000000000",
          "user_description": "User Demonstração",
          "user_id": 111
        },
        {
          "account_bank_ispb": "",
          "account_bank_name": "",
          "account_branch": "",
          "account_number": "",
          "account_owner_name": "User Demonstração",
          "amount": 54,
          "document_number": "",
          "end_to_end_id": "",
          "fee_charged": 0.12,
          "fees_status": "",
          "last_updated_at": "2024-04-24T17:15:45.000",
          "movement_type": "CASH-IN",
          "order_id": "A000A000A000A000A000A000A000A000A00",
          "qr_code_value": "http://faturas.iugu.com/iugu_pix/f57f881f-1a0a-47ce-8320-92b2478f85b5-fa26/test/pay",
          "status": "PAID",
          "transaction_id": "D-00000000-0000-0000-0000-000000000000",
          "user_description": "User Demonstração",
          "user_id": 111
        },
        {
          "account_bank_ispb": "",
          "account_bank_name": "",
          "account_branch": "",
          "account_number": "",
          "account_owner_name": "User Demonstração",
          "amount": 113.70,
          "document_number": "",
          "end_to_end_id": "",
          "fee_charged": 0.18,
          "fees_status": "",
          "last_updated_at": "2024-04-24T21:30:00.000",
          "movement_type": "CASH-IN",
          "order_id": "A000A000A000A000A000A000A000A000A00",
          "qr_code_value": "http://faturas.iugu.com/iugu_pix/f57f881f-1a0a-47ce-8320-92b2478f85b5-fa26/test/pay",
          "status": "EXPIRED",
          "transaction_id": "D-00000000-0000-0000-0000-000000000000",
          "user_description": "User Demonstração",
          "user_id": 111
        },
        {
          "account_bank_ispb": "",
          "account_bank_name": "",
          "account_branch": "",
          "account_number": "",
          "account_owner_name": "User Demonstração",
          "amount": 65.35,
          "document_number": "",
          "end_to_end_id": "",
          "fee_charged": 0.07,
          "fees_status": "",
          "last_updated_at": "2024-04-25T08:30:05.000",
          "movement_type": "CASH-OUT",
          "order_id": "A000A000A000A000A000A000A000A000A00",
          "qr_code_value": "http://faturas.iugu.com/iugu_pix/f57f881f-1a0a-47ce-8320-92b2478f85b5-fa26/test/pay",
          "status": "GENERATED",
          "transaction_id": "D-00000000-0000-0000-0000-000000000000",
          "user_description": "User Demonstração",
          "user_id": 111
        },
        {
          "account_bank_ispb": "",
          "account_bank_name": "",
          "account_branch": "",
          "account_number": "",
          "account_owner_name": "User Demonstração",
          "amount": 80.20,
          "document_number": "",
          "end_to_end_id": "",
          "fee_charged": 0.10,
          "fees_status": "",
          "last_updated_at": "2024-04-25T10:55:12.000",
          "movement_type": "CASH-IN",
          "order_id": "A000A000A000A000A000A000A000A000A00",
          "qr_code_value": "http://faturas.iugu.com/iugu_pix/f57f881f-1a0a-47ce-8320-92b2478f85b5-fa26/test/pay",
          "status": "PAID",
          "transaction_id": "D-00000000-0000-0000-0000-000000000000",
          "user_description": "User Demonstração",
          "user_id": 111
        },
        {
          "account_bank_ispb": "",
          "account_bank_name": "",
          "account_branch": "",
          "account_number": "",
          "account_owner_name": "User Demonstração",
          "amount": 249.80,
          "document_number": "",
          "end_to_end_id": "",
          "fee_charged": 0.21,
          "fees_status": "",
          "last_updated_at": "2024-04-25T12:40:15.000",
          "movement_type": "CASH-IN",
          "order_id": "A000A000A000A000A000A000A000A000A00",
          "qr_code_value": "http://faturas.iugu.com/iugu_pix/f57f881f-1a0a-47ce-8320-92b2478f85b5-fa26/test/pay",
          "status": "PAID",
          "transaction_id": "D-00000000-0000-0000-0000-000000000000",
          "user_description": "User Demonstração",
          "user_id": 111
        },
        {
          "account_bank_ispb": "",
          "account_bank_name": "",
          "account_branch": "",
          "account_number": "",
          "account_owner_name": "User Demonstração",
          "amount": 199.99,
          "document_number": "",
          "end_to_end_id": "",
          "fee_charged": 0.18,
          "fees_status": "",
          "last_updated_at": "2024-04-25T18:25:40.000",
          "movement_type": "CASH-IN",
          "order_id": "A000A000A000A000A000A000A000A000A00",
          "qr_code_value": "http://faturas.iugu.com/iugu_pix/f57f881f-1a0a-47ce-8320-92b2478f85b5-fa26/test/pay",
          "status": "EXPIRED",
          "transaction_id": "D-00000000-0000-0000-0000-000000000000",
          "user_description": "User Demonstração",
          "user_id": 111
        },
        {
          "account_bank_ispb": "",
          "account_bank_name": "",
          "account_branch": "",
          "account_number": "",
          "account_owner_name": "User Demonstração",
          "amount": 75.60,
          "document_number": "",
          "end_to_end_id": "",
          "fee_charged": 0.15,
          "fees_status": "",
          "last_updated_at": "2024-04-25T21:10:05.000",
          "movement_type": "CASH-OUT",
          "order_id": "A000A000A000A000A000A000A000A000A00",
          "qr_code_value": "http://faturas.iugu.com/iugu_pix/f57f881f-1a0a-47ce-8320-92b2478f85b5-fa26/test/pay",
          "status": "GENERATED",
          "transaction_id": "D-00000000-0000-0000-0000-000000000000",
          "user_description": "User Demonstração",
          "user_id": 111
        },
        {
          "account_bank_ispb": "",
          "account_bank_name": "",
          "account_branch": "",
          "account_number": "",
          "account_owner_name": "User Demonstração",
          "amount": 140.65,
          "document_number": "",
          "end_to_end_id": "",
          "fee_charged": 0.11,
          "fees_status": "",
          "last_updated_at": "2024-04-25T22:18:10.000",
          "movement_type": "CASH-IN",
          "order_id": "A000A000A000A000A000A000A000A000A00",
          "qr_code_value": "http://faturas.iugu.com/iugu_pix/f57f881f-1a0a-47ce-8320-92b2478f85b5-fa26/test/pay",
          "status": "PAID",
          "transaction_id": "D-00000000-0000-0000-0000-000000000000",
          "user_description": "User Demonstração",
          "user_id": 111
        },
        {
          "account_bank_ispb": "",
          "account_bank_name": "",
          "account_branch": "",
          "account_number": "",
          "account_owner_name": "User Demonstração",
          "amount": 80,
          "document_number": "",
          "end_to_end_id": "",
          "fee_charged": 0.10,
          "fees_status": "",
          "last_updated_at": "2024-04-25T22:55:12.000",
          "movement_type": "CASH-IN",
          "order_id": "A000A000A000A000A000A000A000A000A00",
          "qr_code_value": "http://faturas.iugu.com/iugu_pix/f57f881f-1a0a-47ce-8320-92b2478f85b5-fa26/test/pay",
          "status": "PAID",
          "transaction_id": "D-00000000-0000-0000-0000-000000000000",
          "user_description": "User Demonstração",
          "user_id": 111
        },
        {
          "account_bank_ispb": "",
          "account_bank_name": "",
          "account_branch": "",
          "account_number": "",
          "account_owner_name": "User Demonstração",
          "amount": 130.75,
          "document_number": "",
          "end_to_end_id": "",
          "fee_charged": 0.15,
          "fees_status": "",
          "last_updated_at": "2024-04-25T23:25:55.000",
          "movement_type": "CASH-IN",
          "order_id": "A000A000A000A000A000A000A000A000A00",
          "qr_code_value": "http://faturas.iugu.com/iugu_pix/f57f881f-1a0a-47ce-8320-92b2478f85b5-fa26/test/pay",
          "status": "PAID",
          "transaction_id": "D-00000000-0000-0000-0000-000000000000",
          "user_description": "User Demonstração",
          "user_id": 111
        },
        
      ],

      "totals": {
        "transactions_qty": 10,
      }
  
    }
    
    const cacheKey = JSON.stringify({ page, limit, filters });
    const now = Date.now();

    if (
      getStatementCache[cacheKey] &&
      now - getStatementCache[cacheKey].time < CACHE_TIMER
    ) {
      return getStatementCache[cacheKey].data;
    }

    const response = { status: 200, data: { transactions: statementFixo } };
    if (response.status !== 200) {
      throw new Error("Request failed");
    }

    if (response?.data?.transactions?.length) {
      getStatementCache[cacheKey] = {
        time: now,
        data: statementFixo
      };
    }

    let statementData = response.data;

    return statementFixo;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function getStatementSub(
  allFilters = {},
  token = decrypt(localStorage.getItem("token"))
) {
  try {
    const cacheKey = JSON.stringify({ allFilters });
    const now = Date.now();

    if (
      getStatementSubCache[cacheKey] &&
      now - getStatementSubCache[cacheKey].time < CACHE_TIMER
    ) {
      return getStatementSubCache[cacheKey].data;
    }
    const subToken = allFilters?.subToken;
    const filters = allFilters?.filter;
    const params = {
      subToken,
      filters,
    };

    const response = {data: allFilters}

    if (response.status !== 200) {
      throw new Error("Request failed");
    }
  
    if (response?.data?.transactions?.length) {
      getStatementCache[cacheKey] = {
        time: now,
        data: response.data,
      };
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function getStatementFees(
  page = 1,
  limit = 100,
  filters = {},
  token = decrypt(localStorage.getItem("token"))
) {
  try {
    const cacheKey = JSON.stringify({ page, limit, filters });
    const now = Date.now();

    if (
      getStatementFeeCache[cacheKey] &&
      now - getStatementFeeCache[cacheKey].time < CACHE_TIMER
    ) {
      return getStatementFeeCache[cacheKey].data;
    }

    const params = {
      page,
      limit,
      ...filters,
    };

    const response = await axios.get(`${baseURL}/transactions/fees`, {
      headers: {
        Authorization: token,
      },
      params,
    });

    if (response.status !== 200) {
      throw new Error("Request failed");
    }
    // Store the result in cache only if response.data.length is not null
    if (response?.data?.transactions?.length) {
      getStatementFeeCache[cacheKey] = {
        time: now,
        data: response.data,
      };
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function getBalance() {
  try {
    const balanceFixo = { 
      "balance": "850250.70",
      "description": "Conta Demonstração",
      "virtual_account": "00000000-0000-0000-0000-000000000000"
    }
    const valorBalanceFixo = balanceFixo.balance;
    const cacheKey = "balance";
    const now = Date.now();

    if (
      getBalanceCache[cacheKey] &&
      now - getBalanceCache[cacheKey].time < CACHE_TIMER
    ) {
      return getBalanceCache[cacheKey].data;
    }

    getBalanceCache[cacheKey] = {
      time: now,
      data: balanceFixo,
    };
    return {balanceFixo, valorBalanceFixo};
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}
async function getUsers(
  page = 1,
  limit = 100,
  filters = {},
  token = decrypt(localStorage.getItem("token"))
) {
  try {
    const fixedUserList = [{
      user_id: 2,
      is_parent: 0,
      user_parent_id: 1,
      description: "SubConta 2",
      email: "subConta1@demo.com",
      external_token: "000000000",
      pix_key: "000000000",
      balance: 500,
      total_transactions: 5,
      virtual_account: "0000",
      status: "Aprovada",
      total_users: "8"
      },
]

    const cacheKey = JSON.stringify({ page, limit, filters });
    const now = Date.now();
    if (
      getUsersCache[cacheKey] &&
      now - getUsersCache[cacheKey].time < CACHE_TIMER
    ) {
      return getUsersCache[cacheKey].data;
    }

    const params = {
      page,
      limit,
      ...filters,
    };

    const response = {data: fixedUserList}

    if (response.status !== 200) {
      throw new Error("Request failed");
    }
    getUsersCache[cacheKey] = {
      time: now,
      data: response.data,
    };

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}
async function getAllDocuments(
  user_id,
  token = decrypt(localStorage.getItem("token"))
) {
  try {
    const cacheKey = "documents";
    const now = Date.now();
    /* if (
      getDocumentsCache[cacheKey] &&
      now - getDocumentsCache[cacheKey].time < CACHE_TIMER
    ) {
      return getDocumentsCache[cacheKey].data;
    } */
    const params = {
      user_id: user_id,
    };
  
    const response = {data: user_id}

    if (response.status !== 200) {
      throw new Error("Request failed");
    }
    getDocumentsCache[cacheKey] = {
      time: now,
      data: response.data,
    };
    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function downloadCSV(
  date,
  token = decrypt(localStorage.getItem("token"))
) {
  try {
    const response = {data: date}

    if (response.status !== 200) {
      throw new Error("Request failed");
    }

    return response?.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function forceUpdateMetrics(
  token = decrypt(localStorage.getItem("token"))
) {
  try {
    const cacheKey = "metrics";
    const now = Date.now();

    if (
      getMetricsCache[cacheKey] &&
      now - getMetricsCache[cacheKey].time < CACHE_TIMER * 15
    ) {
      return getMetricsCache[cacheKey].data;
    }

    const response = {data: token}

    if (response.status !== 200 && response.status !== 204) {
      throw new Error("Request failed");
    }

    getMetricsCache[cacheKey] = {
      time: now,
      data: response.data,
    };
  
    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function getMetrics(
  status = null,
  movementType = null,
  date = null,
  fromDate = null,
  toDate = null,
  token = decrypt(localStorage.getItem("token"))
  
) {
  try {

    const getMetricsFixo = {
      "daily_metrics_per_user":[],
      "transactions_average_value": "64",
      "transactions_average_value_quantity": "0",
      "transactions_generated_per_minute": "0",
      "transactions_paid_per_minute": "0",
      "transactions_per_hour": [40, 55, 30, 75, 90, 45, 20, 15, 60, 65],
      "transactions_success_rate": "0",
      "transactions_summary": {
      "total_amount_cash_in" : "983491.00",
      "total_amount_cash_out": "590094.60",
      "total_amount_tef": "10",
      "total_fee_charged": "10",
      "total_transactions_cash_in": 15799,
      "total_transactions_cash_out": 9820,
      "total_transactions_tef": 655
      }
    }
    const cacheKey = "metrics";
    const now = Date.now();
    const today = date || format(now, "yyyy-MM-dd");
    let url = `${baseURL}/metrics?`;

    if (fromDate && toDate) {
      url += `&fromdate=${fromDate}&todate=${toDate}`;
    } else {
      url += `date=${today}`;
    }

    if (status) {
      url += `&status=${status}`;
    }

    if (
      getMetricsCache[cacheKey] &&
      now - getMetricsCache[cacheKey].time < CACHE_TIMER
    ) {
      return getMetricsCache[cacheKey].data;
    }

    getMetricsCache[cacheKey] = {
      time: today,
      data: getMetricsFixo,
    };

    return getMetricsFixo
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}
async function getSubMetrics(
  status = null,
  movementType = null,
  date = null,
  fromDate = null,
  toDate = null,
  subToken,
  token = decrypt(localStorage.getItem("token"))
) {
  try {
    if (!subToken) throw new Error("No sub account setted.");
    const cacheKey = "submetrics";
    const now = Date.now();
    checkIfShouldLogout();
    if (
      getMetricsCache[cacheKey] &&
      now - getMetricsCache[cacheKey].time < CACHE_TIMER
    ) {
      return getMetricsCache[cacheKey].data;
    }
    const data = {
      status: status,
      movementType: movementType,
      date: date,
      fromdate: fromDate,
      todate: toDate,
      subToken: subToken,
    };
    const today = date || format(now, "yyyy-MM-dd");
    const response = {data:status}

    if (response.status !== 200) {
      throw new Error("Request failed");
    }

    getMetricsCache[cacheKey] = {
      time: today,
      data: response.data,
    };

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}
async function authenticate() {
  try {
    const userFixo = {
        "account_name": "user_demo@demo.com",
        "username": "user_demo",
        "password": "123",
        "token": "00000000-0000-0000-0000-000000000000",
        "is_admin": 1,
        "is_fees_account": null,
        "virtual_account": "00000000-0000-0000-0000-000000000000",
        "webhook_url": "",
        "2fa_email": "",
        "pix_key": "00000000-0000-0000-0000-000000000000",
        "access_level": 1
    };

    const response = {data: userFixo}
    
    localStorage.setItem("token", encrypt(response.data.token));
    localStorage.setItem("tokenTime", new Date().getTime());
    localStorage.setItem("last_login", Date.now());
    if (response?.data?.username)
      localStorage.setItem("username", encrypt(response?.data?.username));
    if (response?.data?.password)
      localStorage.setItem("password", encrypt(response?.data?.password));
    return response.data;
  } catch (error) {
    console.error(error);
    localStorage.clear();
    /* window.location.reload(); */
    if (error.response) {
      console.log("Response Status:", error.response.status); // This will log 403
      console.log("Response Data:", error.response.data);
    } else {
      console.error("Error:", error.message);
    }
    throw new Error("Something went wrong");
  }
}

async function updateUserInfo(
  dataToChange,
  token = decrypt(localStorage.getItem("token"))
) {
  try {
    const data = {
      ...dataToChange,
    };

    const response = {data: dataToChange}
    console.log("DATA DO USER", data);
    
    if (response.status !== 200) {
      throw new Error("Request failed");
    }
    if (dataToChange.dashboard_password !== localStorage.getItem("password")) {
      localStorage.clear();
    }
    
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Something went wrong");
  }
}

async function updateStatus(
  status,
  user_id,
  token = decrypt(localStorage.getItem("token"))
) {
  try {
    const data = {
      status: status,
      user_id: user_id,
    };

    const response = {data: status, user_id}

    if (response.status !== 200) {
      console.log(response);
      throw new Error("Request failed");
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}
async function withdraw(
  pinCode,
  amount,
  pixKey,
  typePix,
  token = decrypt(localStorage.getItem("token"))
) {
  try {
    const data = {
      pin_code: pinCode,
      amount: amount * 100,
      key: pixKey,
      type: typePix,
      external_reference: "manual withdraw through dashboard",
    };

    const response = {data: amount, pixKey, pinCode, token}

    if (response.status !== 200) {
      console.log(response);
      throw new Error("Request failed");
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function requestWithdraw(
  amount,
  pixKey,
  typePix,
  token = decrypt(localStorage.getItem("token"))
) {
  try {
    const data = {
      amount: amount * 100, // convert to cents
      pix_key: pixKey,
      pix_type: typePix,
      order_id: "request withdraw through dashboard",
    };

    const response = {data: amount, pixKey, token}

    if (response.status !== 200) {
      throw new Error("Request failed");
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function tef(
  amount,
  virtualAccount,
  token = decrypt(localStorage.getItem("token"))
) {
  try {
    const data = {
      amount: amount * 100, // convert to cents
      virtual_account: virtualAccount,
      order_id: "manual TEF through dasboard",
    };

    const response = {data: amount}

    if (response.status !== 200) {
      throw new Error(response?.message || "Request failed");
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error(error?.message || "Something went wrong");
  }
}

async function generateCashIn(
  amount,
  cpf_cnpj,
  name,
  email,
  token = decrypt(localStorage.getItem("token"))
) {
  try {
    const dateNow = dayjs().format("YYYY-MM-DD");
    const data = {
      amount: amount * 100, // convert to cents
      due_date: dateNow,
      external_reference: "cash-in-dash",
      payer: {
        cpf_cnpj: cpf_cnpj,
        name: name,
        email: email,
      },
    };

    const response = {data: amount}

    if (response.status !== 200) {
      console.log(response);
      throw new Error("Request failed");
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

export const fixedUserList = [
  {
  user_id: 2,
  is_parent: 0,
  user_parent_id: 1,
  description: "SubConta 2",
  email: "subConta2@demo.com",
  external_token: "000000000",
  pix_key: "000000000",
  balance: 500,
  total_transactions: 5,
  virtual_account: "00002",
  status: "Aprovada",
  total_users: "8"
  },
  {
  user_id: 3,
  is_parent: 0,
  user_parent_id: 1,
  description: "SubConta 3",
  email: "subConta3@demo.com",
  external_token: "000000000",
  pix_key: "000000000",
  balance: 100,
  total_transactions: 1,
  virtual_account: "00003",
  status: "Bloqueada",
  total_users: "8"
  },
  {
  user_id: 4,
  is_parent: 0,
  user_parent_id: 1,
  description: "SubConta 4",
  email: "subConta4@demo.com",
  external_token: "000000000",
  pix_key: "000000000",
  balance: 0,
  total_transactions: 0,
  virtual_account: "00004",
  status: "Pendente",
  total_users: "8"
  },
  {
  user_id: 5,
  is_parent: 0,
  user_parent_id: 1,
  description: "SubConta 5",
  email: "subConta5@demo.com",
  external_token: "000000000",
  pix_key: "000000000",
  balance: 900,
  total_transactions: 8,
  virtual_account: "00005",
  status: "Suspensa",
  total_users: "8"
  },
  {
  user_id: 6,
  is_parent: 0,
  user_parent_id: 1,
  description: "SubConta 6",
  email: "subConta6@demo.com",
  external_token: "000000000",
  pix_key: "000000000",
  balance: 1000,
  total_transactions: 10,
  virtual_account: "00006",
  status: "Aprovada",
  total_users: "8"
  },
  {
  user_id: 7,
  is_parent: 0,
  user_parent_id: 1,
  description: "SubConta 7",
  email: "subConta7@demo.com",
  external_token: "000000000",
  pix_key: "000000000",
  balance: 75,
  total_transactions: 5,
  virtual_account: "00007",
  status: "Bloqueada",
  total_users: "8"
  },
  {
  user_id: 8,
  is_parent: 0,
  user_parent_id: 1,
  description: "SubConta 8",
  email: "subConta8@demo.com",
  external_token: "000000000",
  pix_key: "000000000",
  balance: 0,
  total_transactions: 0,
  virtual_account: "00008",
  status: "Pendente",
  total_users: "8"
  },
  {
  user_id: 9,
  is_parent: 0,
  user_parent_id: 1,
  description: "SubConta 9",
  email: "subConta9@demo.com",
  external_token: "000000000",
  pix_key: "000000000",
  balance: 500,
  total_transactions: 5,
  virtual_account: "00009",
  status: "Suspensa",
  total_users: "8"
  },
]

export {
  authenticate,
  downloadCSV, forceUpdateMetrics, generateCashIn, getAllDocuments,
  getBalance, getMetrics, getStatement, getStatementFees,
  getStatementSub, getSubMetrics, getUsers, requestWithdraw, tef, updateStatus, updateUserInfo, withdraw
};

