import CryptoJS from "crypto-js";

const secretKey = import.meta.env.VITE_KEY_CRYPTO;
// Encryption function
function encrypt(data) {
  const encryptedData = CryptoJS.AES.encrypt(data, secretKey).toString();

  return encryptedData;
}

// Decryption function
function decrypt(encryptedData) {
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey);

  const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);

  return decryptedData;
}

// Export the functions
export { encrypt, decrypt };
