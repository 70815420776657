import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../context/loggedUserContext";
import { authenticate } from "../services/TransactionsService";
import { decrypt } from "../utils/crypto.mjs";
const PrivateRoutePayments = () => {
  const { user } = useContext(UserContext);
  if (!user) {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    (async function () {
      try {
        /* Update effect logic to track correct state */
        const response = await authenticate(
          decrypt(username),
          decrypt(password)
        );
        const accessLevel = response?.access_level;
        const isTrueSet = accessLevel === 1;

        switch (accessLevel) {
          case 2:
            return isTrueSet ? <Outlet /> : <Navigate to="/transactions" />;
          case 3:
            return isTrueSet ? <Outlet /> : <Navigate to="/payments" />;
          default:
            return isTrueSet ? <Outlet /> : <Navigate to="/" />;
        }
      } catch (err) {
        console.log(err);
      }
    })();
  } else {
    const accessLevel = user?.access_level;
    const isTrueSet = accessLevel === 1;

    switch (accessLevel) {
      case 2:
        return isTrueSet ? <Outlet /> : <Navigate to="/transactions" />;
      case 3:
        return isTrueSet ? <Outlet /> : <Navigate to="/payments" />;
      default:
        return isTrueSet ? <Outlet /> : <Navigate to="/" />;
    }
  }
};
export default PrivateRoutePayments;
