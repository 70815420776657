import { createContext, useState } from "react";

const SubTokenContext = createContext();

export const SubTokenProvider = ({ children }) => {
  const [token, setToken] = useState("");

  const updateToken = (newValue) => {
    setToken(newValue);
  };

  return (
    <SubTokenContext.Provider value={{ token, updateToken }}>
      {children}
    </SubTokenContext.Provider>
  );
};

export default SubTokenContext;
