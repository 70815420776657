import { createContext, useEffect, useState } from "react";
import { authenticate } from "../services/TransactionsService";
import { decrypt } from "../utils/crypto.mjs";
const UserContext = createContext();
const UserContextProvider = ({ children }) => {
  const username = localStorage.getItem("username");
  const password = localStorage.getItem("password");
  const [user, setUser] = useState(null);
  useEffect(() => {
    async function fetchUser() {
      const response = await authenticate(decrypt(username), decrypt(password));
      if (response) {
        setUser(response);
      }
    }
    if (username && password) {
      fetchUser();
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
