/* eslint-disable react-hooks/rules-of-hooks */
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { createTheme } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QueryClient } from "react-query";
import { useMediaQuery } from "react-responsive";
import { DarkModeContext } from "../../context/darkModeContext.jsx"; // Import the DarkModeContext
import { fixedUserList } from "../../services/TransactionsService.jsx";
const queryClient = new QueryClient();
const labelFontSize = "1.05rem";
const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: labelFontSize,
          fontWeight: 700,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& > fieldset > legend": {
            fontSize: `calc(0.75 * ${labelFontSize})`,
          },
        },
      },
    },
  },
});
export default function tableUser({ filterData, token }) {
  const { darkMode } = useContext(DarkModeContext);
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [loading, setLoading] = useState(true);
  const [usersRows, setusersRows] = useState([]);
  const [totalElements, setTotalElements] = React.useState(1);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);


  useEffect(() => {

    const fetchStatement = async () => {
      try {
        setLoading(true);
        setusersRows(fixedUserList);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setusersRows([]);
        console.error(error);
        return;
      }
    };
    fetchStatement();
    
  }, [filterData, page, rowsPerPage, token]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 15));
    setPage(0);
  };
  if (loading) {
    return (
      <div className="text-center mt-24">
        <CircularProgress color="inherit" />
      </div>
    ); // Render a loading state while data is being fetched
  }
  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">ID Virtual</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Saldo</TableCell>
              <TableCell align="center">Qtd de Transações</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          {!loading && (
            <TableBody>
              {usersRows.map((row) => (
                <TableRow
                  key={row.user_id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{row.user_id}</TableCell>
                  <TableCell align="center">{row.description}</TableCell>
                  <TableCell align="center">{row.virtual_account}</TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                  <TableCell align="center">{row.balance}</TableCell>
                  <TableCell align="center">{row.total_transactions}</TableCell>
                  <TableCell align="center">{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15]}
        component="div"
        count={totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="table"
        labelDisplayedRows={({ from, to, count }) =>
          t("Mostrando") +
          ` ${from} ` +
          t("a") +
          ` ${to} ` +
          t("de um total de") +
          ` ${count} ` +
          t("transações")
        }
      />
    </div>
  );
}
