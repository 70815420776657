import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const isLogged = localStorage.getItem("username");

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return isLogged ? <Outlet /> : <Navigate to="/login" />;
};
export default PrivateRoute;
