import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Tab, TabList, TabPanel, Tabs } from "@mui/joy";
import { Box, Divider, Menu, styled } from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/pt-br";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterDataContext } from "../../context/filterDataContext";
import { getMetrics } from "../../services/TransactionsService";

const FilterMetrics = ({ anchorEl, open, onClose, onClearFilter }) => {
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [maxDate, setMaxDate] = useState(dayjs());
  const {
    filterDate,
    filterStatus,
    setFilterDate,
    setFilterStatus,
    setIsFiltered,
  } = useContext(FilterDataContext);
  const { t, i18n } = useTranslation();
  const [tabOpen, setTabOpen] = useState(0);
  const [localFilterStatus, setLocalFilterStatus] = useState(filterStatus);

  const CustomStaticDatePicker = styled(StaticDatePicker)(({ theme }) => ({
    ".MuiPickersToolbar-root": {
      display: "none",
    },
    ".MuiDialogActions-root": {
      display: "none",
    },
    ".MuiPickersDay-root.Mui-selected": {
      backgroundColor: "#283650",
    },
    ".MuiPickersDay-root.Mui-selected:hover": {
      backgroundColor: "#ca4838",
    },
    ".MuiPickersCalendarHeader-label": {
      textTransform: "capitalize",
    },
  }));

  const handleFromDate = (date) => {
    setStartDate(date);
    setEndDate(null);
    setMaxDate(handleMaxDate(date));
  };

  const handleToDate = (date) => {
    setEndDate(date);
  };

  const handleMaxDate = (date) => {
    const daysAfter = date.add(7, "day");

    if (daysAfter.isAfter(dayjs())) {
      return dayjs();
    }

    return daysAfter;
  };

  const handleFilterData = () => {
    if (startDate && endDate) {
      setFilterDate({
        fromDate: startDate.format("YYYY-MM-DD"),
        toDate: endDate.format("YYYY-MM-DD"),
      });
      setIsFiltered(true);
    }

    setTimeout(() => {
      onClose();
    }, 500);
  };

  const handleLocalFilterStatus = (filterStatusKey) => {
    setLocalFilterStatus(filterStatusKey);
  };

  const handleOpenTab = (event, newValue) => {
    setTabOpen(newValue);
  };

  const handleCloseTab = () => {
    onClose();
  };

  const handleFilterStatus = async () => {
    try {
      setFilterStatus(localFilterStatus);
      await getMetrics(filterStatus, null, null, null, null);

      setTimeout(() => {
        onClose();
      }, 500);
    } catch (error) {
      console.log("error do filtro", error);
    }
  };

  const handleFilterClean = () => {
    setStartDate(dayjs());
    setEndDate(dayjs());
    setFilterDate(dayjs());
    setFilterStatus("");
    onClearFilter();
    setTimeout(() => {
      onClose();
    }, 500);
  };

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
      <div>
        <Tabs orientation="vertical" value={tabOpen} onChange={handleOpenTab}>
          <TabList>
            <Tab onClick={() => setTabOpen(0)}>{t("Data")}</Tab>
            {/* <Tab onClick={() => setTabOpen(1)}>{('Status')}</Tab> */}
          </TabList>
          <TabPanel value={0} sx={{ height: "425px", width: "660px" }}>
            <Box className="flex justify-between">
              <Box>{t("Selecione um intervalo de no máximo 7 dias")}.</Box>
              <Box className="px-5 flex justify-end">
                <HighlightOffIcon
                  className="cursor-pointer hover:scale-125"
                  onClick={handleCloseTab}
                />
              </Box>
            </Box>

            <Box display={"flex"}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={i18n.language === "pt-BR" ? "pt-br" : "en"}
              >
                <CustomStaticDatePicker
                  value={startDate}
                  onChange={handleFromDate}
                  disableFuture
                />
              </LocalizationProvider>
              <Divider orientation="vertical" />
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={i18n.language === "pt-BR" ? "pt-br" : "en"}
              >
                <CustomStaticDatePicker
                  minDate={startDate}
                  value={endDate}
                  maxDate={maxDate}
                  onChange={handleToDate}
                  disableFuture
                />
              </LocalizationProvider>
            </Box>
            <Box display={"flex"} justifyContent={"end"} gap={2}>
              <div
                onClick={handleFilterClean}
                className="text-center w-3/12 cursor-pointer border-iziblue border-2 text-iziblue font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired hover:border-izired"
              >
                {t("Limpar Filtro")}
              </div>
              <div
                onClick={handleFilterData}
                className="text-center w-3/12 cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired"
              >
                {t("Aplicar Filtro")}
              </div>
            </Box>
          </TabPanel>

          {/* <TabPanel value={1} sx={{height: '425px', width: '660px'}}>
                    <Box className="flex justify-between">
                            <Box>{t('Selecione um Status')}.</Box>
                            <Box className="px-5 flex justify-end">
                                <HighlightOffIcon className="cursor-pointer hover:scale-125" onClick={handleCloseTab}/>
                            </Box>
                    </Box>
                    <Box>
                <Box display={'flex'} className="py-1">
                <List>
                {Object.keys(filterStatusOptions).map((key) => 
                <ListItemButton
                key={key} 
                selected={localFilterStatus === key} 
                onClick={()=> {
                    if (key=== "AllTransactions") {
                        handleFilterClean()
                    } else {
                        handleLocalFilterStatus(key)
                    }
                }} 
                value={key}
                >
                {filterStatusOptions[key]}
                {localFilterStatus === key && (
                    <CheckIcon style={{ marginLeft: 'auto' }}>check</CheckIcon>
                )}
                </ListItemButton>
                )}
                </List>
                </Box>
                </Box>
                <Box display={'flex'} justifyContent={'end'} gap={2}>
                         <div onClick={handleFilterStatus} className="text-center w-3/12 cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired">{t('Aplicar Filtro')}</div>
                           </Box>
                    </TabPanel> */}
        </Tabs>
      </div>
    </Menu>
  );
};

export default FilterMetrics;
