import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../context/loggedUserContext";
import { authenticate } from "../services/TransactionsService";
import { decrypt } from "../utils/crypto.mjs";
const PrivateRoute = () => {
  const { user } = useContext(UserContext);
  if (!user) {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    (async function () {
      try {
        /* Update effect logic to track correct state */
        const response = await authenticate(
          decrypt(username),
          decrypt(password)
        );
        const isAdmin = response?.is_admin || response?.is_parent;
        const isTrueSet = isAdmin === 1;

        // If authorized, return an outlet that will render child elements
        // If not, return element that will navigate to login page
        return isTrueSet ? <Outlet /> : <Navigate to="/" />;
      } catch (err) {
        console.log(err);
      }
    })();
  } else {
    const isAdmin = user?.is_admin || user?.is_parent;
    const isTrueSet = isAdmin === 1;

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return isTrueSet ? <Outlet /> : <Navigate to="/" />;
  }
};
export default PrivateRoute;
