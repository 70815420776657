import React from "react";
import {generatePDF, getRawPDF} from "../../components/receipt/Receipt";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./receipts.scss";
import Widget from "../../components/widget/Widget";
import { QueryClient, QueryClientProvider } from "react-query";
import { PDFViewer } from '@react-pdf/renderer';
const queryClient = new QueryClient();

const data = {
  "transaction_id": "8fed4b0a6c8f4673b70da0ec06025306",
  "order_id": "20230725180252R01P9679208418",
  "qr_code_value": "MDAwMjAxMDEwMjEyMjY4MTAwMTRici5nb3YuYmNiLnBpeDI1NTljb3JlLm1rYmFuay5jb20uYnIvYXBpL2NvYi84NzRiM2U4Y2Q0MzY0MGZmODZjYTUwMTZjYjhiYTQxNzUyMDQwMDAwNTMwMzk4NjU4MDJCUjU5MjVSTVBBWSBNRUlPUyBERSBQQUdBTUVOVE9TNjAxMlBPUlRPIEFMRUdSRTYyMDcwNTAzKioqNjMwNDU0QkU=",
  "amount": 30,
  "fee_charged": 0.22,
  "status": "PAID",
  "movement_type": "CASH-IN",
  "fees_status": "CHARGED",
  "last_updated_at": "2023-07-25T21:03:50.000Z",
  "account_bank_ispb": "20855875",
  "account_bank_name": "NEON PAGAMENTOS S.A. IP",
  "account_branch": "0655",
  "account_number": "121518817",
  "document_number": "10975974661",
  "account_owner_name": "viviara de Jesus Sousa",
  "addressing_key": "cb756166-39d1-4c6a-9f90-6ebeee564876"
}

export const handleGeneratePDF = () => {
  generatePDF(data);
};

const isEnabled = false

const Receipts = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="receipts">
        <Sidebar />
        {
          isEnabled && <div className="receiptsContainer">
          <Widget />
          <div className="listContainer">
            <div className="listTitle">Download transaction receipt</div>
            <div className="navbarAndButtonContainer">
              <Navbar />
              <button onClick={handleGeneratePDF}>Generate Receipt</button>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "calc(100vh - 72px)" }}>
            <PDFViewer style={{ width: "100%", height: "100%" }}>
              {getRawPDF(data)}
            </PDFViewer>
          </div>
        </div>
        }
      </div>
    </QueryClientProvider>
  );
};

export default Receipts;
