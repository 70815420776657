import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import PixIcon from "@mui/icons-material/Pix";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import SpeedIcon from "@mui/icons-material/Speed";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { FilterDataContext } from "../../context/filterDataContext.jsx";
import { UserContext } from "../../context/loggedUserContext.jsx";
import SubTokenContext from "../../context/subTokenContext";
import { getMetrics, getSubMetrics } from "../../services/TransactionsService";
const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
});

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));

const queryClient = new QueryClient();

const Widget = ({ type, metrics }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  if (isMobile) {
    import("./widgetMobile.scss");
  } else {
    import("./widget.scss");
  }
  const [balanceon, setBalanceon] = useState(false);
  useEffect(() => {
    if (metrics != undefined) {
      setBalanceon(true);
    }
  }, [metrics]);
  const { t } = useTranslation(); // Use the useTranslation hook to get the t() function
  const balance = metrics?.balance || 0;
  const transactionsAverageValueQuantity =
    metrics?.transactions_average_value_quantity || 0;
  const transactionsAverageValue = metrics?.transactions_average_value || 0;
  const feeReceived = metrics?.transactions_summary?.total_fee_charged || 0;
  const totalCashInOrders =
    metrics?.transactions_summary?.total_transactions_cash_in || 0;
  const totalCashInAmount =
    metrics?.transactions_summary?.total_amount_cash_in || 0;
  const totalCashOutOrders =
    metrics?.transactions_summary?.total_transactions_cash_out || 0;
  const totalCashOutAmount =
    metrics?.transactions_summary?.total_amount_cash_out || 0;
  const totalTefOrders =
    metrics?.transactions_summary?.total_transactions_tef || 0;
  const customerFeeCharged =
    metrics?.transactions_summary?.total_fee_charged || 0;
  const operationalFeeCost = (totalCashInOrders + totalCashOutOrders) * 0.09;
  const operationalcost =
    totalCashInAmount - (totalCashOutAmount + feeReceived);
  const totaltransactions =
    totalCashInOrders + totalCashOutOrders + totalTefOrders;

  const d = new Date();
  let actualTimeStamp =
    d.toLocaleTimeString("pt-BR") + " " + d.toLocaleDateString("pt-BR");
  const formatToBRLCurrency = (rawValue) => {
    const value = parseFloat(rawValue);
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };
  const [displayText, setDisplayText] = useState("∗∗∗∗∗");

  const handleClick = () => {
    // Toggle between two different texts
    const newText =
      displayText === "∗∗∗∗∗" ? formatToBRLCurrency(Number(balance)) : "∗∗∗∗∗";
    setDisplayText(newText);
  };
  const [isActive, setIsActive] = useState(false);

  let data;

  switch (type) {
    case "transactions_per_minute":
      data = {
        title: t("Quantidade de transações"),
        tooltip: t("Total de transações efetivadas."),
        link: t("Check my metrics"),
        valueclass: "text-center text-3xl mdscreen:text-3xl ",
        value: 567,
        class:
          "justify-center w-full h-full widget p-2.5 rounded-lg flex flex-col",
        diff: null,
        topCornerIcon: null,
        lastupdate: actualTimeStamp,
        icon: (
          <SpeedIcon
            className="icon"
            style={{
              color: "dodgerblue",
              backgroundColor: "rgba(30, 144, 255, 0.2)",
            }}
          />
        ),
      };
      break;
    case "average_ticket":
      data = {
        title: t("Ticket médio"),
        tooltip: t("Valor médio por transação."),
        link: t("Check my fees"),
        valueclass: "text-center text-3xl mdscreen:text-3xl",
        value: transactionsAverageValue
          ? formatToBRLCurrency(Number(transactionsAverageValue))
          : "-",
        class:
          "justify-center w-full h-full widget p-2.5 rounded-lg flex flex-col",
        diff: transactionsAverageValueQuantity,
        topCornerIcon: null,
        icon: (
          <AttachMoneyIcon
            className="icon"
            style={{
              color: "green",
              backgroundColor: "rgba(0, 128, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "fee_to_pay":
      data = {
        title: t("Taxas"),
        tooltip: t("Custos associados às transações."),
        link: t("Check my fees"),
        valueclass: "text-center text-3xl mdscreen:text-3xl",
        value: customerFeeCharged
          ? formatToBRLCurrency(customerFeeCharged)
          : "-",
        class:
          "justify-center w-full h-full widget p-2.5 rounded-lg flex flex-col",
        diff: null,
        topCornerIcon: null,
        icon: (
          <MoneyOffIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "fee_to_pay_admin":
      data = {
        title: t("Taxas"),
        tooltip: t("Custos associados às transações."),
        link: t("Check my fees"),
        valueclass: "text-center text-3xl mdscreen:text-3xl",
        value: operationalFeeCost
          ? formatToBRLCurrency(operationalFeeCost)
          : "-",
        class:
          "justify-center w-full h-full widget p-2.5 rounded-lg flex flex-col",
        diff: null,
        topCornerIcon: null,
        icon: (
          <MoneyOffIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "net_fee_received":
      data = {
        title: t("Net fee received"),
        link: t("Check my fees"),
        valueclass: "text-center text-3xl mdscreen:text-3xl",
        value: feeReceived
          ? formatToBRLCurrency(feeReceived - operationalFeeCost)
          : "-",
        class: "justify-center widget p-2.5 rounded-lg flex flex-col",
        diff: null,
        topCornerIcon: null,
        icon: (
          <AttachMoneyIcon
            className="icon"
            style={{
              color: "green",
              backgroundColor: "rgba(0, 128, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "balance":
      data = {
        title: t("Balance"),
        tooltip: t("Saldo total da conta."),
        graph: null,
        titleclass: "w-full",
        valueclass: "text-3xl mdscreen:text-5xl",
        link: t("Check my balance"),
        value: (
          <div className="flex items-center gap-2">
            <button onClick={handleClick}>
              <div onClick={() => setIsActive(!isActive)}>
                {isActive ? (
                  <MdVisibility
                    color="#183350"
                    className="cursor-pointer"
                    size={20}
                  />
                ) : (
                  <MdVisibilityOff
                    color="#183350"
                    className="cursor-pointer"
                    size={20}
                  />
                )}
              </div>
            </button>
            <div>{displayText}</div>
          </div>
        ),
        class:
          "w-full shadow-xl border-t-2 border-l-2 w-3/12 widget p-2.5 rounded-lg flex flex-col",
        icon: (
          <AccountBalanceIcon
            className="icon"
            style={{
              color: "green",
              backgroundColor: "rgba(0, 128, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "cash_in_orders":
      data = {
        title: t("Cash-in"),
        link: t("View all orders"),
        valueclass: "text-center text-3xl mdscreen:text-3xl",
        tooltip: t("Transações de entrada."),
        value: totalCashInAmount ? formatToBRLCurrency(totalCashInAmount) : "-",
        class:
          "justify-center w-full h-full widget p-2.5 rounded-lg flex flex-col",
        diff: totalCashInOrders ?? 0,
        icon: (
          <PixIcon
            className="icon"
            style={{
              color: "green",
              backgroundColor: "rgba(0, 128, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "fees_received":
      data = {
        title: t("Fees receveid"),
        link: t("View all orders"),
        valueclass: "text-center text-3xl mdscreen:text-3xl",
        value: feeReceived ? formatToBRLCurrency(feeReceived) : "-",
        class: "justify-center widget p-2.5 rounded-lg flex flex-col",
        diff: totalTefOrders ?? 0,
        icon: (
          <PixIcon
            className="icon"
            style={{
              color: "green",
              backgroundColor: "rgba(0, 128, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "cash_out_orders":
      data = {
        title: t("Cash-out"),
        valueclass: "text-center text-3xl mdscreen:text-3xl",
        tooltip: t("Transações de saída."),
        link: t("View all orders"),
        value: totalCashOutAmount
          ? formatToBRLCurrency(totalCashOutAmount)
          : "-",
        class:
          "justify-center w-full h-full widget p-2.5 rounded-lg flex flex-col",
        diff: totalCashOutOrders ?? 0,
        icon: (
          <AccountBalanceIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "operational_result":
      data = {
        title: t("Resultado operacional"),
        divtitleclass: "justify-center",
        valueclass: "text-center text-3xl mdscreen:text-3xl",
        tooltip: t("Resultado financeiro da operação."),
        graph: null,
        link: t("View all orders"),
        value: operationalcost ? formatToBRLCurrency(operationalcost) : "-",
        class:
          " w-full h-full justify-center widget p-2.5 rounded-lg flex flex-col",
        diff: "",
        icon: (
          <AccountBalanceIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "fee_charged":
      data = {
        title: t("Fee paid"),
        link: t("See details"),
        graph: "",
        value: customerFeeCharged
          ? formatToBRLCurrency(customerFeeCharged)
          : "-",
        class: "justify-center widget p-2.5 rounded-lg flex flex-col",
        diff: totalCashInOrders ?? 0 + totalCashOutOrders ?? 0,
        icon: (
          <PointOfSaleIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className={data.class}>
      <div
        className={
          balanceon
            ? "text-xl justify-center w-full flex gap-2 font-medium"
            : "hidden text-xl justify-center w-full gap-2 font-medium"
        }
      >
        <span className={data.titleclass}>{data.title}</span>
        <div className="text-end flex justify-end self-center">
          <ThemeProvider theme={theme}>
            <LightTooltip
              title={data.tooltip}
              TransitionComponent={Zoom}
              placement="right"
            >
              <InfoOutlinedIcon
                style={{ fontSize: "medium", verticalAlign: "top" }}
              />
            </LightTooltip>
          </ThemeProvider>
        </div>
      </div>
      <div
        className={balanceon ? data.valueclass : "hidden " + data.valueclass}
      >
        <span className="counter font-semibold text-nowrap">{data.value}</span>
      </div>
      <div className={balanceon ? "hidden" : "w-full text-center"}>
        <CircularProgress color="inherit" />
      </div>
    </div>
  );
};

const Home = () => {
  const { filterDate, filterStatus } = useContext(FilterDataContext);
  const { token } = useContext(SubTokenContext);
  const { user } = useContext(UserContext);
  let widgets;
  const { data: metrics } = useQuery({
    queryKey: ["metrics", filterDate, filterStatus, token],
    queryFn: () => {
      if (localStorage.getItem("subToken")) {
        return getSubMetrics(
          null,
          null,
          null,
          filterDate.fromDate,
          filterDate.toDate,
          localStorage.getItem("subToken")
        );
      } else {
        return getMetrics(
          null,
          null,
          null,
          filterDate.fromDate,
          filterDate.toDate
        );
      }
    },
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 60 * 1,
    refetchIntervalInBackground: false,
  });
  if (user?.is_admin) {
    widgets = (
      <div className="h-full border-zinc-300 flex flex-col p-0">
        <div className="w-full h-2/3 flex">
          <div className="p-5 border-r-2 border-zinc-300 w-layout-45">
            <Widget type="operational_result" metrics={metrics} />
          </div>
          <div className="p-5 border-r-2 border-zinc-300 w-layout-27">
            <Widget type="cash_in_orders" metrics={metrics} />
          </div>
          <div className="p-5 w-layout-27">
            <Widget type="cash_out_orders" metrics={metrics} />
          </div>
        </div>
        <div className="w-full h-2/3 flex">
          <div className="p-5 border-r-2 border-zinc-300 w-4/12 border-t-2 border-b-2 ">
            <Widget type="average_ticket" metrics={metrics} />
          </div>
          <div className="p-5 border-r-2 border-zinc-300 w-4/12 border-t-2 border-b-2 ">
            <Widget type="fee_to_pay_admin" metrics={metrics} />
          </div>
          <div className="p-5 w-4/12 border-zinc-300 border-t-2 border-b-2">
            <Widget type="transactions_per_minute" metrics={metrics} />
          </div>
        </div>
      </div>
    );
  } else {
    widgets = (
      <div className="h-full border-zinc-300 flex flex-col p-0">
        <div className="w-full h-2/3 flex">
          <div className="p-5 border-r-2 border-zinc-300  w-layout-45">
            <Widget type="operational_result" metrics={metrics} />
          </div>
          <div className="p-5 border-r-2 border-zinc-300 w-layout-27">
            <Widget type="cash_in_orders" metrics={metrics} />
          </div>
          <div className="p-5 w-layout-27">
            <Widget type="cash_out_orders" metrics={metrics} />
          </div>
        </div>
        <div className="w-full h-2/3 flex">
          <div className="p-5 border-r-2 border-zinc-300 w-4/12 border-t-2 border-b-2 ">
            <Widget type="average_ticket" metrics={metrics} />
          </div>
          <div className="p-5 border-r-2 border-zinc-300 w-4/12 border-t-2 border-b-2 ">
            <Widget type="fee_to_pay" metrics={metrics} />
          </div>
          <div className="p-5 w-4/12 border-zinc-300 border-t-2 border-b-2">
            <Widget type="transactions_per_minute" metrics={metrics} />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="home h-full">
      <div className="w-full h-full">{widgets}</div>
    </div>
  );
};

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Home />
    </QueryClientProvider>
  );
};

export default App;
