import Search from "@mui/icons-material/Search.js";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useMediaQuery } from "react-responsive";
import TableUser from "../../components/metricCharts/userTable.jsx";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { DarkModeContext } from "../../context/darkModeContext.jsx"; // Import the DarkModeContext
const queryClient = new QueryClient();
const labelFontSize = "1.05rem";
const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: labelFontSize,
          fontWeight: 700,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& > fieldset > legend": {
            fontSize: `calc(0.75 * ${labelFontSize})`,
          },
        },
      },
    },
  },
});
const SearchUser = ({ onFilterData }) => {
  const removeValueFromArray = (array, value) => {
    return array.filter((item) => item !== value);
  };
  const [checkedVal, setCheckedVal] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [selectStatus, setStatusVal] = useState(null);
  const [activeSearch, setActiveSearch] = useState(false);
  const handleCheckboxChange = (event) => {
    event.target.checked
      ? setCheckedVal([...checkedVal, event.target.value])
      : setCheckedVal(removeValueFromArray(checkedVal, event.target.value));
  };
  const handleSelectChange = (event) => {
    setStatusVal(event.target.value);
  };
  const handleSearch = () => {
    setActiveSearch(!activeSearch);
  };
  useEffect(() => {
    if (searchVal == "") setActiveSearch(!activeSearch);
  }, [searchVal]);
  useEffect(() => {
    const filters = {};
    if (checkedVal.includes("documents")) filters["documents"] = true;
    if (checkedVal.includes("subaccounts")) filters["subaccounts"] = true;
    if ([0, 1, 2, 3].includes(selectStatus)) filters["status"] = selectStatus;
    if (searchVal != "") filters["virtual_account"] = searchVal;
    onFilterData(filters);
  }, [checkedVal, activeSearch, selectStatus]);
  return (
    <div className="flex gap-6 mx-6 mb-6 mt-6 items-center">
      <div className="">
        <FormControl variant="standard">
          <InputLabel htmlFor="input-with-icon-adornment">
            Virtual Account
          </InputLabel>
          <Input
            id="input-with-icon-adornment"
            onChange={(e) => {
              setSearchVal(e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <Search className="cursor-pointer" onClick={handleSearch} />
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      <div className=" w-1/12 ">
        <FormControl fullWidth>
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            id="status"
            selected={32}
            onChange={handleSelectChange}
            label="Status"
          >
            <MenuItem value={32}>Sem Filtro</MenuItem>
            <MenuItem value={1}>Aprovada</MenuItem>
            <MenuItem value={0}>Pendente</MenuItem>
            <MenuItem value={3}>Bloqueada</MenuItem>
            <MenuItem value={2}>Suspensa</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="flex gap-2">
        <div className="">
          <FormControlLabel
            value="documents"
            control={<Checkbox onChange={handleCheckboxChange} />}
            label="Documents"
          />
        </div>
        <div className="">
          <FormControlLabel
            value="subaccounts"
            control={<Checkbox onChange={handleCheckboxChange} />}
            label="Sub Accounts"
          />
        </div>
      </div>
    </div>
  );
};
const UserList = () => {
  const { darkMode } = useContext(DarkModeContext);
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  if (isMobile) {
    import("./adminMobile.scss");
  } else {
    import("./userList.scss");
  }
  const [filterData, setFilterData] = useState({});
  const handleFilterData = (data) => {
    setFilterData(data);
  };
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <div className="profile">
          <Sidebar />
          <div className="w-10/12 sm:w-full">
            <Navbar />

            <div className="h-[calc(100%-112px)] flex flex-col">
              <SearchUser onFilterData={handleFilterData} />

              <TableUser filterData={filterData} />
            </div>
          </div>
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default UserList;
